import { createUseStyles } from 'react-jss';

const useStylesFAQs = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '240px 2rem 120px',
    margin: '0 auto',
    color: 'white',
    width: '83.33%',
    maxWidth: '960px',
  },

  faqCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '16px 0',
    borderBottom: '1px solid #fff',
  },

  description: {
    fontFamily: 'Switzer',
    fontSize: '18px',
    color: '#fff',
    marginTop: '16px',
    lineHeight: '1.6',
    letterSpacing: '0.5px',
  },

  animateCollapse: {
    transition:
      'height 800ms linear(0 0%, 0 2.27%, 0.02 4.53%, 0.04 6.8%, 0.06 9.07%, 0.1 11.33%, 0.14 13.6%, 0.25 18.15%, 0.39 22.7%, 0.52 25.17%, 0.64 27.9%, 0.75 31.39%, 0.86 39.02%, 0.79 47.72%, 0.77 50%, 0.75 52.27%, 0.75 54.55%, 0.75 56.82%, 0.77 59.1%, 0.79 61.38%, 0.81 63.65%, 0.85 65.93%, 0.89 68.2%, 1 72.7%, 0.97 74.98%, 0.95 77.25%, 0.94 79.53%, 0.94 81.8%, 0.94 84.08%, 0.95 86.35%, 0.97 88.63%, 1 90.9%, 0.99 93.18%, 0.98 95.45%, 0.99 97.73%, 1 100%) !important',
  },

  '@media (max-width: 1024px)': {
    container: {
      width: "100%",
      padding: '150px 1.5rem 60px',
    },
  },

});

export default useStylesFAQs;
