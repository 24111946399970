import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  main: {
    backgroundColor: 'var(--main-background-color)'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '240px 2rem 120px',
    width: "83.33%",
    maxWidth: '1280px',
    margin: '0 auto',
    color: 'white',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: "100%",
    gap: "4rem"
  },
  RowSocial: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  column: {
    width: '61.8%',
    display: 'flex',
    flexDirection: 'column',
    gap: "2rem"
  },
  colImage: {
    width: '38.2%',
  },
  ratio: {
    paddingBottom: '100%',
    position: 'sticky',
    top: '240px',
    border: '2px solid var(--green)',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  selectedbyImage: {
    maxWidth: '97px',
    margin: '1rem 0',
  },
  date: {
    fontSize: '24px',
    color: 'var(--green)',
    fontWeight: '600',
    marginBottom: '8px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    marginTop: "-2.5rem"
  },
  title: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '32px',
    lineHeight: '1em',
    letterSpacing: '1.2px',
    color: 'var(--green)'
  },
  role: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: 23,
    letterSpacing: 0.4,
  },
  description: {
    fontSize: '18px',
    lineHeight: '32px',
    color: 'white'
  },
  selectedby: {
    fontSize: '20px',
    fontWeight: '600',
    margin: '3rem 0 1rem',
    color: 'var(--green)',
  },
  conferenceList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '24px',
    '@media (max-width: 1024px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@media (max-width: 768px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
    }
  },
  embedPlayer: {
    width: "100%",
  },
  socials: {
    width: 'auto',
    height: '24px',
    marginRight: '16px',
    cursor: 'pointer',
  },

  embed: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '2rem 0',
    borderRadius: '20px',
    overflow: 'hidden',
  },
  '@media (max-width: 1024px)': {
    container: {
      width: "100%",
      padding: '120px 1.5rem 80px 1.5rem',
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
    },
    column: {
      width: '100%',
      gap: "1.5rem"
    },
    colImage: {
      width: '100%',
      marginBottom: '0.5rem',
    },
  },

});

export default useStyles;
