import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { airtableApi } from 'services/api.js';
import { normalizeString } from 'utils/helpers.js';
import Header from 'components/common/header';
import Footer from 'components/common/footer';
import EventCard from 'components/event-card';
import useStyles from './styles';
import { marked } from 'marked';


marked.use({
  renderer: {
    link(href) {
      return `<a href="${href.href}" target="_blank" rel="noopener noreferrer">${href.text}</a>`;
    }
  }
});

const SpeakerDetailPage = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const lang = pathname.startsWith('/pt') ? 'pt' : 'en';
  const { name } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const formattedName = normalizeString(name.replace(/-/g, ' '));

  const { data, error, isLoading } = useQuery({
    queryKey: ['speakers'],
    queryFn: airtableApi.getSpeakers,
  });

  const { data: scheduleData } = useQuery({
    queryKey: ['schedule'],
    queryFn: airtableApi.getSchedule,
  });

  const speaker = data?.find((speaker) => normalizeString(speaker.fields.Name) === formattedName);

  const speakerSchedule = scheduleData?.filter(item =>
    speaker?.fields.Schedule?.includes(item.id)
  );

  return (
    <>
      <Header conference lang={lang} />
      <main className={classes.main}>
        <div className={classes.container}>
          {isLoading ? (
            <p>Loading speaker details...</p>
          ) : error ? (
            <p>Error loading speaker details: {error.message}</p>
          ) : !speaker ? (
            <p>No speaker found with the name: {formattedName}</p>
          ) : (
            <>
              <div className={classes.row}>
                <div className={classes.colImage}>
                  <div className={classes.ratio}>
                    {speaker.fields.Images && speaker.fields.Images.length > 0 && (
                      <img
                        className={classes.image}
                        src={speaker.fields.Images[1]?.thumbnails.full.url || speaker.fields.Images[0].thumbnails.full.url}
                        alt={speaker.fields.Name}
                      />
                    )}
                  </div>
                </div>

                <div className={classes.column}>
                  <div className={classes.titleContainer}>
                    <h1 className={classes.title}>{speaker.fields.Name}</h1>
                    <p className={classes.role}>{lang === 'en' ? speaker.fields.Role : speaker.fields['Role PT']}</p>
                  </div>
                  <div
                    className={classes.description}
                    dangerouslySetInnerHTML={{
                      __html: marked(lang === 'en'
                        ? (speaker.fields.Bio || '')
                        : (speaker.fields['Bio PT'] || '')
                      ),
                    }}
                  />
                </div>
              </div>

              {speakerSchedule && speakerSchedule.length > 0 && (
                <>
                  <p className={classes.selectedby}>
                    {lang === 'en' ? 'Related events: ' : 'Eventos relacionados: '}
                  </p>

                  <div className={classes.conferenceList}>
                    {speakerSchedule.map(event => (
                      <EventCard
                        key={event.id}
                        event={event}
                        lang={lang}
                      />
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </main>
      <Footer lang={'en'} />
    </>
  );
};

export default SpeakerDetailPage;
