import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import useStylesProPanel from './styles';
import useStyles from '../styles';
import { airtableApi } from 'services/api';
import SquareSGreen from 'assets/SquareSGreen.svg';
import arrowBack from 'assets/icons/arrow_back.svg';
import { EmailStep, VerificationStep, ProfileStep } from '../../components/steps';

const Login = () => {
  const classesGlobal = useStyles();
  const classes = useStylesProPanel();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [actualVerificationCode, setActualVerificationCode] = useState(null);

  const handleConfirm = async () => {
    try {
      if (!email || !/\S+@\S+\.\S+/.test(email)) {
        alert('Please enter a valid email address');
        return;
      }

      setIsLoading(true);
      const response = await airtableApi.sendVerificationCode(email);
      setActualVerificationCode(response.verificationCode);
      setShowVerification(true);
    } catch (error) {
      console.error('Error sending verification code:', error);
      alert('Failed to send verification code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerificationSuccess = () => {
    setIsVerified(true);
    setActualVerificationCode(null);
  };

  return (
    <div className={classesGlobal.App}>
      <main className={`${classesGlobal.main} ${'pro-panel'}`}>
        <div className={classes.container}>
          <div className={classes.logInNav}>
            <div className={classes.logo}>
              <img style={{ width: '100%' }} src={SquareSGreen} alt="SquareLogo" />
            </div>
            <div className={classes.navRow}>
              <Link className={classes.backButton} to="/">
                <img className={classes.arrowBack} src={arrowBack} alt="arrowBack" />
                <p>Back to website</p>
              </Link>
              <div className={classes.placeholderLanguage} />
            </div>
          </div>
          <div className={classes.login}>
            {!showVerification ? (
              <EmailStep email={email} setEmail={setEmail} isLoading={isLoading} onConfirm={handleConfirm} />
            ) : !isVerified ? (
              <VerificationStep
                email={email}
                actualVerificationCode={actualVerificationCode}
                onVerificationSuccess={handleVerificationSuccess}
                onResendCode={handleConfirm}
              />
            ) : (
              <ProfileStep />
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
