import React, { useEffect } from 'react';
import cx from 'classnames';
import useStyles from '../styles';
import useStylesConference from './styles';
import { useLocation } from 'react-router-dom';

import Header from 'components/common/header';
import SpeakerList from 'components/speakers-list';
import Footer from 'components/common/footer';

import TalkList from 'components/talk-list';

const Conference = () => {
  const classes = useStyles();
  const pageClasses = useStylesConference();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  const [currentTab, setCurrentTab] = React.useState(() => {
    const saved = localStorage.getItem('conferenceTab');
    return saved || 'programme';
  });

  useEffect(() => {
    localStorage.setItem('conferenceTab', currentTab);
  }, [currentTab]);

  return (
    <div className={classes.App}>
      <Header conference lang={lang} />
      <main className={classes.main}>
        <div className={classes.container}>
          <div className={pageClasses.introSection}>
            <h1 className={pageClasses.title}>{lang === 'en' ? 'MARGIN' : 'MARGEM'}</h1>
            {lang === 'en' ? (
              <p className={pageClasses.description}>
                SQUARE's first edition will explore the polysemy of the concept of "margin" delving into its possible
                meanings in its three-day convention programme.
                <br />
                <br /> On the first day, we approach the physical margins of the countries bathed by the Atlantic. An
                abstract exploration of the ideas of geomusicality, terrain, and accidentality.
                <br />
                <br /> On the second day, margins are viewed in opposition to the centre, examining the different layers
                of this dichotomy. We will discuss access and accessibility and the ever-present idea of a "centre" (be
                it Anglo-Saxon or European).
                <br />
                <br /> On the final day, attention shifts to the survival of independent artists. Time to examine the
                artist as a worker, and other aspects of labour including the "financial" margins. How can the music
                industry become less precarious? How can artists navigate an increasingly scarce space?
              </p>
            ) : (
              <p className={pageClasses.description}>
                O programa da conferência do Square 2025 explora a polissemia do conceito de “margem”, aprofundando, em
                cada um dos dias, os seus possíveis significados.
                <br />
                <br /> No primeiro dia, propõe-se uma abordagem às margens físicas dos países banhados pelo Atlântico,
                através da exploração abstracta de ideias de geomusicalidade, terreno e acidentalidade.
                <br />
                <br /> No segundo dia, olha-se a margem em oposição ao centro. Aborda-se a dicotomia periferia versus
                centro nas suas diferentes camadas. Discute-se o acesso e a acessibilidade. Fala-se de limitação e da
                ideia de centralidade anglosaxónica, e europeia.
                <br />
                <br /> No último dia, voltam-se as atenções para a questão da sobrevivência de artistas independentes:
                olhando não só a vertente do trabalho (em tudo o que isso implica), mas também as questões financeiras
                associadas. Como tornar a indústria menos precária? Como podem os artistas navegar um espaço cada vez
                mais rarefeito?
              </p>
            )}
          </div>
          <div className={pageClasses.tabMenu}>
            <div
              className={cx(pageClasses.tabMenuItem, { current: currentTab === 'programme' })}
              onClick={() => setCurrentTab('programme')}
              style={{ pointerEvents: 'auto', opacity: '100%' }}
            >
              <div className={"p-relative"}>
                <p>{lang === 'en' ? 'PROGRAMME' : 'PROGRAMA'}</p>
                {currentTab === 'programme' && <div className={pageClasses.current} />}
              </div>
            </div>
            <div
              className={cx(pageClasses.tabMenuItem, { current: currentTab === 'speakers' })}
              onClick={() => setCurrentTab('speakers')}
            >
              <div className={"p-relative"}>
                <p>{lang === 'en' ? 'SPEAKERS' : 'ORADORES'}</p>
                {currentTab === 'speakers' && <div className={pageClasses.current} />}
              </div>
            </div>
          </div>
          {currentTab === 'programme' && <TalkList lang={lang} />}
          {currentTab === 'speakers' && <SpeakerList lang={lang} />}
        </div>
      </main>
      <Footer lang={lang} />
    </div>
  );
};

export default Conference;
