import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import clock from 'assets/icons/clock_white.svg';
import poi from 'assets/icons/poi_white.svg';
import { formatNameForUrl } from '../../utils/helpers';

const EventCard = ({ event, lang }) => {
  const classes = useStyles();

  return (
    <Link
      to={
        lang === 'en'
          ? `/event/${formatNameForUrl(event.fields.Name)}`
          : `/pt/evento/${formatNameForUrl(event.fields.Name)}`
      }
      className={classes.event}
    >
      <div className={classes.row}>
        <div className={classes.tag}>
          <img src={clock} alt="clock" className={classes.tagIcon} />
          <p className={classes.tagText}>
            {event.fields['Name (from Day)']}
          </p>
        </div>
        <div className={`${classes.tag} type`}>
          <p className={classes.type}>{event.fields['Name (from Tags)']}</p>
        </div>
      </div>
      <div className={classes.eventTitle}>
        {lang === 'en' ? event.fields.Title : event.fields['Title PT']}
      </div>
      <div className={classes.tag}>
        <img src={poi} alt="location" className={classes.tagIcon} />
        <div className={classes.tagText}>{event.fields['Name (from Venue)']}</div>
      </div>
      <div className={classes.speakersRow}>
        <p className={classes.speakers}>
          {event.fields.Speakers && (lang === 'en' ? 'with ' : 'com ')}
          {event.fields.Speakers ? event.fields.Speakers : 'TBC'}
        </p>
      </div>
      <div className={classes.city}>{event.fields['Name (from City)']}</div>

    </Link>
  );
};

export default EventCard;
