import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  list: {
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1.5rem',
    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr',
      gap: '1rem',
    }
  },
  noResults: {
    width: '100%',
    padding: '2rem 0',
    fontSize: '1.2rem',
    fontWeight: 500,
  },
});

export default useStyles;
