import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { airtableApi } from '../../services/api';
import useStyles from './styles';
import PropTypes from 'prop-types';

const Partners = ({ lang }) => {
  const classes = useStyles();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['partners'],
    queryFn: airtableApi.getPartners,
  });

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error: {error.message}</p>;


  const partnersByTitle = data.reduce((acc, record) => {
    const title = record.fields.Name;
    const titlePT = record.fields["Title PT"] || title; // Use Title PT if available, fallback to Name
    const images = record.fields.Images || [];

    if (!acc[title]) {
      acc[title] = {
        title: title,
        titlePT: titlePT,
        images: [],
      };
    }

    acc[title].images.push(
      ...images.map((img) => ({
        url: img.thumbnails?.large?.url || '',
        alt: img.filename || '',
      }))
    );

    return acc;
  }, {});


  return (
    <section className={classes.partnerSection}>
      <p className={classes.partnersTitle}>
        {lang === 'en' ? 'Partners' : 'Parceiros'}
      </p>

      <div className={classes.partnersMaxWidth}>
        {/* Dynamic partner sections based on titles and images */}
        {Object.entries(partnersByTitle).map(([_, { title, titlePT, images }], index) => (
          <React.Fragment key={index}>
            {title !== 'Main' && (
              <p className={classes.partnerTitle}>
                {lang === 'en' ? title : titlePT}
              </p>
            )}
            <div className={classes.partnerList}>
              {images.map((image, idx) => (
                <div className={classes.partner} key={idx}>
                  <img
                    src={image.url}
                    alt={image.alt}
                    loading="lazy"
                    className={title === 'Main' ? `big` : ''}
                  />
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}


      </div>
    </section>
  );
};

Partners.propTypes = {
  lang: PropTypes.string,
};

export default Partners;
