import React from 'react';
import { useLocation } from 'react-router-dom';
import useStyles from './styles';
import { useQuery } from '@tanstack/react-query';

import { airtableApi } from 'services/api';
import EventCard from 'components/event-card';

const TalkList = () => {
  const classes = useStyles();
  const location = useLocation();
  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  const {
    data: allSchedule = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ['talks'],
    queryFn: airtableApi.getSchedule,
  });

  const talks = allSchedule.filter((item) =>
    item.fields['Name (from Type)']?.includes('Talks')
  );

  return (
    <div className={classes.list}>
      {isLoading && <div>Loading...</div>}
      {error && <div>Error: {error.message}</div>}

      {!isLoading &&
        !error &&
        talks.map((event, index) => (
          <EventCard
            key={index}
            event={event}
            lang={lang}
          />
        ))}
    </div>
  );
};

export default TalkList;
