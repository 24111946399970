import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  speakerCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    backgroundColor: '#40320D',
    '&:hover': {
      textDecoration: 'none',
    },
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },

  imageContainer: {
    width: '25%',
    minWidth: '100px',
    display: 'flex',
    border: '3px solid var(--green)',
    '@media (max-width: 1024px)': {
      minWidth: '110px',
    },
  },
  ratio: {
    width: '100%',
    position: 'relative',
    paddingBottom: '100%',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  speakerDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: '18px',
    width: '100%',
    '&.desktop': {
      padding: '12px',
      '@media (max-width: 1024px)': {
        display: 'none',
      },
    },
    '@media (max-width: 1024px)': {
      padding: '1rem',
    },
  },

  speakerTitle: {
    fontFamily: 'Switzer',
    fontSize: '16px',
    fontWeight: 600,
    color: 'var(--green)',
    lineHeight: '24px',
    letterSpacing: '1px',
    margin: '0',
    '&.desktop': {
      display: 'none',
      '@media (max-width: 1024px)': {
        display: 'block',
      },
    },
    '@media (max-width: 1024px)': {
      margin: '0 0 18px 0',
      lineHeight: '1.2em',
    },
  },

  speakerAbout: {
    fontFamily: 'Switzer',
    fontSize: '14px',
    fontWeight: 600,
    color: '#F3F3F3',
    lineHeight: '24px',
    letterSpacing: '1px',
    '&.country': {
      color: 'var(--green)',
      lineHeight: '12px',
      marginBottom: '4px',
    },
    '@media (max-width: 1024px)': {
      lineHeight: '1.2em',
    },
  },
});

export default useStyles;
