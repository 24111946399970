import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({

  speakerCard: {
    display: 'flex',
    flexDirection: 'column',
    color: 'var(--green)',
    transition: 'all 0.2s ease',
    '& img': {
      width: '100%',
      objectFit: 'cover',
    },
    '& img.big': {
      height: '4.2cm',
      padding: 0,
    },
  },
  speakerDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '1.5rem',
    gap: '1rem'
  },
  imageContainer: {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  speakersTitle: {
    fontFamily: 'Switzer',
    fontSize: 32,
    fontWeight: 600,
    wordWrap: 'break-word',
    '& span': {
      fontSize: '16px',
    },
  },
  speakersAbout: {
    fontFamily: 'Switzer',
    fontSize: 16,
    letterSpacing: 0.4
  },
  speakerTitle: {
    fontFamily: 'Switzer',
    fontSize: 'calc(24px + 1vw)',
    fontWeight: '600',
    textAlign: 'center',
    color: 'var(--red)',
  },
  detailButton: {
    marginTop: '10px',
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  '@media (max-width: 1024px)': {
    container: {
      width: "100%",
    },
    speakersList: {
      gap: '2rem',
    },
    speakers: {
      width: "100%"
    },
    speakersSection: {
      padding: '4rem 1rem',
    },
  },
});

export default useStyles;
