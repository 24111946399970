import { createUseStyles } from 'react-jss';

const useStylesTickets = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '240px 2rem 120px',
    maxWidth: '960px',
    margin: '0 auto',
    width: '83.33%',
  },

  ticketBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '40px',
    margin: '0 auto',
    backgroundColor: 'var(--light-main-background-color)',
    color: '#fff',
    '& ul': {
      padding: 0,
      margin: 0,
      '& li': {
        padding: '10px 0',
      },
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
    gap: '2rem',
    marginBottom: '2rem',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '32px',
  },
  price: {
    fontFamily: 'Switzer',
    fontSize: '24px',
    fontWeight: '600',
    color: 'var(--main-color)',
  },
  description: {
    fontFamily: 'Switzer',
    fontSize: '18px',
    fontWeight: '400',
    margin: '0px',
    lineHeight: '1.6',
    letterSpacing: '0.4px',
  },

  bullets: {
    fontFamily: 'Switzer',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '1.6',
    letterSpacing: '0.4px',
  },

  '@media (max-width: 1024px)': {
    container: {
      width: "100%",
      padding: '120px 1.5rem 80px 1.5rem',
    },
    ticketBox: {
      padding: '1.5rem',
    },
  },
});

export default useStylesTickets;
