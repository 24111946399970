import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { marked } from 'marked';
import { airtableApi } from 'services/api';
import Header from 'components/common/header';
import TheSpacer from 'components/common/spacer/thespacer';
import Footer from 'components/common/footer';
import useStyles from './styles';

marked.use({
  renderer: {
    link(href) {
      return `<a href="${href.href}" target="_blank" rel="noopener noreferrer">${href.text}</a>`;
    }
  }
});

const NewsDetailPage = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { name } = useParams();
  const lang = pathname.startsWith('/pt') ? 'pt' : 'en';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { data } = useQuery({
    queryKey: ['news'],
    queryFn: airtableApi.getNews,
    select: (data) => data.filter((item) => item.fields.Status !== 'In progress'),
  });

  const news = data
    ? data.find((item) => lang === 'pt'
      ? item.fields.slugPT === name
      : item.fields.slug === name)
    : null;

  return (
    <>
      <Header news lang={lang} data={data} />
      <main className={classes.main}>
        <div className={classes.container}>
          {!news ? (
            <p>No news found with the name: {name}</p>
          ) : (
            <>
              <div className={classes.row}>
                <div className={classes.colImage}>
                  <div className={classes.ratio}>
                    <img
                      className={classes.image}
                      src={
                        (news.fields.Images && news.fields.Images[1] && news.fields.Images[1].thumbnails?.full?.url) ||
                        (news.fields.Images && news.fields.Images[0] && news.fields.Images[0].thumbnails?.full?.url)
                      }
                      alt={news.fields.Name}
                    />
                  </div>
                </div>
                <TheSpacer size={32} horizontal />
                <div className={classes.column}>
                  <p className={classes.date}>{news.fields.Date}</p>
                  <h1 className={classes.title}>{lang === 'pt' ? news.fields['Name PT'] : news.fields.Name}</h1>
                </div>
              </div>
              <TheSpacer size={32} vertical />
              <div
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html: lang === 'pt' ? marked(news.fields['Description PT'] || '') : marked(news.fields.Description || ''),
                }}
              />
            </>
          )}
        </div>
      </main>
      <Footer lang={lang} />
    </>
  );
};

export default NewsDetailPage;
