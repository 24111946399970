import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  main: {
    backgroundColor: 'white'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '240px 2rem 120px',
    width: "83.33%",
    maxWidth: '1280px',
    margin: '0 auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: "100%",
    gap: "4rem"
  },
  rowArtist: {
    display: 'flex',
    flexDirection: 'row',
    gap: "1rem",
    color: 'var(--red)',
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    }
  },
  column: {
    width: '61.8%',
    display: 'flex',
    flexDirection: 'column',
    gap: "2rem",
    position: "relative",
  },
  colImage: {
    width: '38.2%',
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  image: {
    objectFit: 'contain',
    width: "200px",
    position: "sticky",
    top: "240px"
  },
  selectedbyImage: {
    maxWidth: '97px',
    margin: '1rem 0',
  },
  title: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '32px',
    lineHeight: '1em',
    letterSpacing: '1.2px',
    color: 'var(--red)'
  },
  artistTitle: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '25px',
    lineHeight: '1em',
    letterSpacing: '1.2px',
    color: 'var(--red)'
  },
  description: {
    fontSize: '18px',
    lineHeight: '32px',
    color: 'var(--brown)'
  },
  selectedby: {
    fontSize: '18px',
    color: 'var(--brown)'
  },
  colArtistImage: {
    width: '35%',
  },
  colArtist: {
    display: "flex",
    flexDirection: "column",
    width: '65%',
    gap: "1rem",
    padding: "1rem"
  },
  ratio: {
    width: "100%",
    paddingBottom: '100%',
    position: 'relative',
    border: '2px solid var(--green)',
  },
  imageArtist: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  button: {
    fontFamily: 'Switzer',
    fontSize: 22,
    fontWeight: 600,
    textTransform: "uppercase",
    color: 'var(--red)',
    marginTop: "auto",
  },
  '@media (max-width: 1024px)': {
    container: {
      width: "100%",
      padding: '120px 1.5rem 80px 1.5rem',
    },
    row: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    column: {
      width: '100%',
      gap: "1.5rem"
    },
    colImage: {
      width: '100%',
      padding: '1rem',
    },
  },

});

export default useStyles;
