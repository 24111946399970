import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useStylesFAQs from './styles';
import useStyles from '../styles';
import Collapsible from 'react-collapsible';

import Header from 'components/common/header';
import Footer from 'components/common/footer';

const FAQs = () => {
  const classesGlobal = useStyles();
  const classes = useStylesFAQs();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  const faqList = [
    {
      question: 'What is SQUARE?',
      description:
        "SQUARE is a festival-conference dedicated to enhancing the exchange of knowledge among professionals in the music industry and promoting the circulation of emerging bands and musical projects across the different territories bordering the Atlantic. SQUARE is part of the Braga'25 - Portuguese Capital of Culture program.",
    },
    {
      question: 'Who can participate in SQUARE?',
      description:
        'Everyone. SQUARE is open to participation from professionals in the music and arts sector, those curious about the music industry, and the general public interested in attending concerts. For this, three types of tickets have been created, offering experiences tailored to different interests. Detailed information about what each ticket includes, prices, and points of sale can be found in the tickets section.',
    },
    {
      question: 'Where does SQUARE take place?',
      description:
        "SQUARE's main location is the city of Braga, which will host the entire Conference program, the daily clubbing events (starting at 11:30 PM), and all of Saturday February 1st music programming. During the first three days, SQUARE will organize showcases in the cities of Barcelos, Famalicão, and Guimarães.",
    },
    {
      question: 'How can I travel between the cities?',
      description: 'By private car or public transportation. Holders of the PRO PASS will have access to transfers.',
    },
    {
      question: 'Where should I stay?',
      description: "Most of SQUARE's activities take place in Braga, so we recommend staying in the city.",
    },
    {
      question: 'When will the final event lineup and daily and venue-specific program distribution be announced?',
      description:
        'The final lineup will be announced by the end of 2024. The daily and venue-specific distribution will be communicated in January 2025.',
    },
    {
      question: 'Are there any free admission activities?',
      description:
        'Yes. The showcases happening in the cities of Barcelos, Famalicão, and Guimarães are free to access, pending on each venue capacity.',
    },
    {
      question: 'Are there any age restrictions?',
      description:
        'SQUARE is an event for all ages. Children from 3 years old can attend, provided they are accompanied by their parents and within the applied legal guidelines. SQUARE will include festival venues like bars and concert halls, which may restrict access to minors. Individual passes are required for anyone aged 12 or older.',
    },
    {
      question: 'Will there be alcohol at all events? Can I smoke indoors?',
      description:
        'Most venues have bars with drinks available. However, some locations do not serve alcohol, nor is its consumption allowed on the premises. The same applies to smoking, which varies by venue. The rules of each location will be clearly marked.',
    },
    {
      question: "I'm a journalist and would like to cover the festival, what should I do?",
      description:
        'Journalists interested in covering the festival should contact the press team at the email saracunha@gmail.com, providing their name, media outlet they work for, dates of attendance, and a summary of the coverage they intend to produce.',
    },
    {
      question:
        "I'm an artist; I have a band; I want to propose a partnership; I have an idea for SQUARE. Who should I contact?",
      description:
        'Proposals should be sent to the curatorial team via the email info@squarefestival.pt. Submitting music proposals for consideration for the 2025 edition of SQUARE is no longer possible. We will announce the open call for future editions when the time is right.',
    },
  ];

  const faqListPT = [
    {
      question: 'O que é o SQUARE?',
      description:
        "O SQUARE é um festival-conferência dedicado a potenciar o cruzamento de conhecimentos entre profissionais do sector musical e a circulação de bandas e projetos musicais emergentes entre os diferentes territórios banhados pelo Atlântico. O SQUARE é parte integrante do programa da Braga'25 - Capital Portuguesa da Cultura.",
    },
    {
      question: 'Quem pode participar no SQUARE?',
      description:
        'Toda a gente. O SQUARE está aberto à participação de profissionais do sector da música e da arte, curiosos pelo universo musical e público em geral que tenha apenas interesse em ver concertos. Para tal, foram criados três tipos de bilhetes que dão acesso a experiências direcionadas para diferentes interesses. Informações detalhadas sobre o que integra cada bilhete, preços e pontos de venda na secção bilhetes.',
    },
    {
      question: 'Onde decorre o SQUARE?',
      description:
        'O SQUARE terá como ponto central a cidade de Braga, que será o palco para todo o programa da Conferência, para toda a programação de clubbing (a decorrer todos os dias a partir das 23:30), assim como toda a programação de música de sábado dia 1 de fevereiro. Nos três primeiros dias, o SQUARE organizará showcases/concertos nas cidades de Barcelos, Famalicão e Guimarães.',
    },
    {
      question: 'Como me posso deslocar entre as cidades?',
      description:
        'Através de carro próprio ou transportes públicos. Os portadores de PASSE PRO terão acesso a transfers.',
    },
    {
      question: 'Onde devo alojar-me?',
      description:
        'A grande parte das actividades do SQUARE decorrem em Braga, pelo que aconselhamos a estadia na cidade.',
    },
    {
      question: 'Quando é comunicado o cartaz final do evento e a distribuição do programa por dias e espaços?',
      description:
        'O cartaz final será anunciado até ao final de 2024. A distribuição por dias e salas será comunicada em janeiro de 2025.',
    },
    {
      question: 'Existe alguma atividade de entrada livre?',
      description:
        'Sim. Os concertos/showcases a decorrerem nas cidades de Barcelos, Famalicão e Guimarães são de acesso livre mediante lotação das salas.',
    },
    {
      question: 'Existem restrições de idade?',
      description:
        'O SQUARE é um evento para todas as idades. A entrada de crianças a partir dos 3 anos, quando acompanhadas pelos pais, é possível, desde que legalmente enquadrada. O SQUARE integrará como espaços do festival bares, salas de concertos entre outros espaços que, poderão, não permitir a entrada de menores. A compra de passe individual é obrigatória a partir dos 12 anos.',
    },
    {
      question: 'Haverá álcool em todos os eventos? É possível fumar no interior?',
      description:
        'A maioria dos locais tem um bar com bebidas disponíveis. No entanto, alguns espaços não servem álcool, nem o seu consumo é permitido nas instalações. O mesmo se aplica a fumar, que varia de um lugar para o outro. As regras de cada local estão devidamente sinalizadas.',
    },
    {
      question: 'Sou jornalista e pretendo fazer a cobertura do festival, como devo proceder?',
      description:
        'Os jornalistas interessados em fazer a cobertura do festival deverão contatar a equipa de assessoria de imprensa através do email saracunha@gmail.com, com indicação do nome, meio para o qual colabora, datas de presença e um resumo da cobertura que pretendem fazer.',
    },
    {
      question:
        'Sou artista; tenho uma banda; quero fazer uma proposta de parceria; tenho uma ideia para o SQUARE. Com quem devo falar?',
      description:
        'Propostas devem ser endereçadas à equipa curatorial através do email info@squarefestival.pt. O envio de propostas musicais para a apreciação no âmbito da edição 2025 do SQUARE já não é possível. Daremos nota da abertura da open call para futuras edições, quando o mesmo for oportuno.',
    },
  ];

  return (
    <div className={classesGlobal.App}>
      <Header faqs lang={lang} isSingle />

      <main className={classesGlobal.main}>
        <div className={classes.container}>
          {lang === 'en'
            ? faqList.map((faq, index) => (
              <div key={index} className={classes.faqCard}>
                <Collapsible trigger={faq.question} contentOuterClassName={classes.animateCollapse}>
                  <p className={classes.description}>{faq.description}</p>
                </Collapsible>
              </div>
            ))
            : faqListPT.map((faq, index) => (
              <div key={index} className={classes.faqCard}>
                <Collapsible trigger={faq.question} contentOuterClassName={classes.animateCollapse}>
                  <p className={classes.description}>{faq.description}</p>
                </Collapsible>
              </div>
            ))}
        </div>
      </main>

      <Footer lang={lang} />
    </div>
  );
};

export default FAQs;
