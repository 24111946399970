import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import useStyles from '../styles';
import useStylesArtists from './styles';
import { useLocation } from 'react-router-dom';
import Header from 'components/common/header';
import ArtistCard from 'components/artist-card';
import Footer from 'components/common/footer';
import { useQuery } from '@tanstack/react-query';
import { airtableApi } from '../../services/api';
import LineupList from 'components/lineup-list';

const ArtistsPage = () => {
  const classes = useStyles();
  const pageClasses = useStylesArtists();

  const location = useLocation();
  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { data: artistsData, error: artistsError, isLoading: artistsLoading } = useQuery({
    queryKey: ['artistas'],
    queryFn: airtableApi.getArtists,
  });

  const { data: scheduleData, error: scheduleError, isLoading: scheduleLoading } = useQuery({
    queryKey: ['schedule'],
    queryFn: airtableApi.getSchedule,
  });

  const sortedData = artistsData ? [...artistsData].sort((a, b) => a.fields.Name.localeCompare(b.fields.Name)) : [];

  const [currentTab, setCurrentTab] = React.useState(() => {
    const saved = localStorage.getItem('artistsTab');
    return saved || 'programme';
  });

  useEffect(() => {
    localStorage.setItem('artistsTab', currentTab);
  }, [currentTab]);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [timeFilter, setTimeFilter] = useState(null);

  const cities = ['Braga', 'Guimarães', 'Barcelos', 'Famalicão'];

  const handleDateSelect = (date) => {
    if (selectedDate === date) {
      setSelectedDate(null);
    } else {
      setSelectedDate(date);
      setTimeFilter(null);
      setSelectedCity(null);
      setIsDropdownOpen(false);
    }
  };

  const handleCitySelect = (city) => {
    if (selectedCity === city) {
      setSelectedCity(null);
    } else {
      setSelectedCity(city);
      setTimeFilter(null);
    }
    setIsDropdownOpen(false);
  };

  const showFilters = selectedDate !== '1 FEB';

  return (
    <div className={classes.App}>
      <Header lang={lang} artists />
      <main className={`${classes.main} ${'theme-light'}`}>
        <div className={classes.container}>

          <div className={pageClasses.tabMenu}>
            <div
              className={cx(pageClasses.tabMenuItem, { current: currentTab === 'programme' })}
              onClick={() => setCurrentTab('programme')}
              style={{ pointerEvents: 'auto', opacity: '100%' }}
            >
              <div className={"p-relative"}>
                <p>{lang === 'en' ? 'Lineup' : 'Programação'}</p>
                {currentTab === 'programme' && <div className={pageClasses.current} />}
              </div>
            </div>
            <div
              className={cx(pageClasses.tabMenuItem, { current: currentTab === 'speakers' })}
              onClick={() => setCurrentTab('speakers')}
            >
              <div className={"p-relative"}>
                <p>{lang === 'en' ? 'Artists' : 'Artistas'}</p>
                {currentTab === 'speakers' && <div className={pageClasses.current} />}
              </div>
            </div>
          </div>

          {currentTab === 'programme' && (
            <div className={pageClasses.filtersContainer}>
              <div className={pageClasses.dateSelectorContainer}>
                {['29 JAN', '30 JAN', '31 JAN', '1 FEB'].map((date) => (
                  <div
                    key={date}
                    className={cx(pageClasses.dateItem, {
                      [pageClasses.active]: selectedDate === date
                    })}
                    onClick={() => handleDateSelect(date)}
                  >
                    <div>{date}</div>
                    {selectedDate === date && <div className={pageClasses.activeLine} />}
                  </div>
                ))}
              </div>

              {showFilters && (
                <>
                  <div className={pageClasses.timeAndCityFilterContainer}>
                    <div
                      className={cx(pageClasses.timeFilterButton, {
                        [pageClasses.activeTimeFilter]: timeFilter === 'day'
                      })}
                      onClick={() => setTimeFilter(timeFilter === 'day' ? null : 'day')}
                    >
                      <div className={pageClasses.timeFilterButtonText}>
                        {lang === 'en' ? 'By day' : 'De dia'}
                        {timeFilter === 'day' && <div className={pageClasses.timeFilterLine} />}
                      </div>
                    </div>
                    <div
                      className={cx(pageClasses.timeFilterButton, {
                        [pageClasses.activeTimeFilter]: timeFilter === 'night'
                      })}
                      onClick={() => setTimeFilter(timeFilter === 'night' ? null : 'night')}
                    >
                      <div className={pageClasses.timeFilterButtonText}>
                        {lang === 'en' ? 'By night' : 'De noite'}
                        {timeFilter === 'night' && <div className={pageClasses.timeFilterLine} />}
                      </div>
                    </div>

                    <div className={pageClasses.cityFilterContainer}>
                      <div
                        className={cx(pageClasses.cityDropdownButton, {
                          [pageClasses.activeCity]: selectedCity || isDropdownOpen
                        })}
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      >
                        <span>{selectedCity || (lang === 'en' ? 'City' : 'Cidade')}</span>
                      </div>

                      {isDropdownOpen && (
                        <div className={pageClasses.cityDropdownMenu}>
                          <div
                            className={cx(pageClasses.cityOption, {
                              [pageClasses.activeCity]: !selectedCity
                            })}
                            onClick={() => {
                              setSelectedCity(null);
                              setIsDropdownOpen(false);
                            }}
                          >
                            {lang === 'en' ? 'All' : 'Todos'}
                          </div>
                          {cities.map(city => (
                            <div
                              key={city}
                              className={cx(pageClasses.cityOption, {
                                [pageClasses.activeCity]: selectedCity === city
                              })}
                              onClick={() => handleCitySelect(city)}
                            >
                              {city}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {currentTab === 'programme' && (
            <LineupList
              selectedDate={selectedDate}
              selectedCity={selectedCity}
              timeFilter={timeFilter}
              scheduleData={scheduleData}
              isLoading={scheduleLoading}
              error={scheduleError}
            />
          )}

          {currentTab === 'speakers' && (
            <div className={classes.artistsList}>
              {artistsLoading && <p>Loading...</p>}
              {artistsError && <p>Error loading data: {artistsError.message}</p>}

              {!artistsLoading &&
                !artistsError &&
                sortedData.map((artist) => (
                  <ArtistCard key={artist.id} artist={artist} lang={lang} />
                ))}
            </div>
          )}
        </div>
      </main>
      <Footer lang={lang} />
    </div>
  );
};

export default ArtistsPage;
