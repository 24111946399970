import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useStyles from '../../pages/styles';
import Proptypes from 'prop-types';

import { airtableApi } from '../../services/api';

const Section1 = ({ language }) => {
  const classes = useStyles();

  const location = useLocation();
  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  const [expanded, setExpanded] = useState(false);

  // Use useQuery to fetch and cache data
  const { data: records, isLoading, isError } = useQuery({
    queryKey: ['sectionData', lang],
    queryFn: airtableApi.getHomepage,
  });

  // Filter and extract necessary fields after data is fetched
  const filteredRecords = records?.filter((record) => record.fields.Name === 'Square 1') || [];

  let description = '';
  let description2 = '';
  let image1 = '';
  let image1Alt = '';
  let image2 = '';
  let image2Alt = '';

  if (filteredRecords.length > 0) {
    description = lang === 'pt'
      ? filteredRecords[0].fields['Description PT']
      : filteredRecords[0].fields.Description;

    description2 = lang === 'pt'
      ? filteredRecords[0].fields['Descritpion PT 2']
      : filteredRecords[0].fields['Description 2'];

    const assets = filteredRecords[0].fields.Assets || [];

    image1 = assets.length > 0 ? assets[0].thumbnails.large.url : '';
    image1Alt = assets.length > 0 ? assets[0].filename : '';

    image2 = assets.length > 1 ? assets[1].thumbnails.large.url : '';
    image2Alt = assets.length > 1 ? assets[1].filename : '';
  }

  return (
    <section className={`${classes.card} ${classes.bgGreen}`}>
      {isLoading && <p className={classes.loadingText}>Loading...</p>}
      {isError && <p className={classes.errorText}>Error loading data</p>}
      {!isLoading && !isError && (
        <>
          <div className={classes.ratio}>
            <h1 className={classes.cardText}>{description}</h1>

            <div className={classes.seeMoreWrapper}>
              <div className={`${classes.seeMoreHidden} ${expanded ? classes.seeMoreVisible : ''}`}>
                <p className={classes.cardText}>{description2}</p>
              </div>
              <button onClick={() => setExpanded(!expanded)} className={classes.seeMoreButton}>
                <p className={classes.seeMoreText}>
                  {expanded
                    ? lang === 'pt' ? 'Ver menos' : 'View less'
                    : lang === 'pt' ? 'Ver mais' : 'View more'}
                </p>
              </button>
            </div>
          </div>
          <div className={classes.image1}>
            <img src={image1} alt={image1Alt} className={classes.image} loading="lazy" />
          </div>
          <div className={classes.image2}>
            <img src={image2} alt={image2Alt} className={classes.image} loading="lazy" />
          </div>
        </>
      )}
    </section>
  );
};

Section1.propTypes = {
  language: Proptypes.string,
};

export default Section1;
