import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  footerMaxWidth: {
    width: '100%',
    maxWidth: '860px',
    display: 'flex',
  },
  footerCol: {
    display: 'flex',
    width: '50%',
  },
  footerTextWrap: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    gap: '1rem',
    flex: 1,
  },
  footerLink: {
    fontSize: '1.1rem',
    textAlign: 'left',
    color: 'var(--main-color)',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  footerTextLabel: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '20px',
    textAlign: 'left',
    color: 'white',
    letterSpacing: '0.4px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'var(--light-main-background-color)',
    color: 'white',
    padding: '2rem',
  },
  footerLogo: {
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '200px',
    padding: '50px 0',
  },
  '@media (max-width: 1024px)': {
    footer: {
      padding: '1.5rem',
    },
    footerMaxWidth: {
      flexDirection: 'column',
    },
    footerCol: {
      width: '100%',
      justifyContent: 'center',
    },
    footerLogo: {
      padding: '1.5rem 0',
      width: '150px',
    }
  },
});

export default useStyles;
