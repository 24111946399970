import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './styles.js';
import { formatNameForUrl } from '../../utils/helpers.js';
import { capitalizeEveryWord } from '../../utils/helpers.js';

const SpeakerCard = ({ speaker, lang }) => {
  const classes = useStyles();

  return (
    <Link
      to={
        lang === 'pt'
          ? `/pt/orador/${formatNameForUrl(speaker.fields.Name)}`
          : `/speaker/${formatNameForUrl(speaker.fields.Name)}`
      }
      className={classes.speakerCard}
      key={speaker.id}
    >
      <div className={classes.imageContainer}>
        {speaker.fields.Images && speaker.fields.Images.length > 0 && (
          <img
            src={speaker.fields.Images[0].thumbnails.large.url}
            className={classes.image}
            alt={speaker.fields.Name}
            loading="lazy"
          />
        )}
      </div>
      <div className={classes.speakerDetails}>
        <p className={classes.speakersTitle}>
          {capitalizeEveryWord(speaker.fields.Name)} <span>{speaker.fields.Country}</span>
        </p>
        <p className={classes.speakersAbout}>{speaker.fields[lang === 'pt' ? 'Role-PT' : 'Role']}</p>
      </div>
    </Link >
  );
};

export default SpeakerCard;
