import React, { useState } from 'react';
import useStylesProPanel from './styles';
import userPlaceholder from 'assets/icons/user_placeholder.svg';

const ProfileStep = () => {
  const classes = useStylesProPanel();
  const [editForm, setEditForm] = useState({
    fullName: '',
    position: '',
    company: '',
    image: null,
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setEditForm({
        ...editForm,
        image: {
          thumbnails: {
            large: {
              url: URL.createObjectURL(file)
            }
          }
        }
      });
    }
  };

  const handleEditSubmit = () => {
    console.log('Profile updated:', editForm);
  };

  return (
    <>
      <h1 className={classes.editTitle}>Finish your profile</h1>
      <p className={classes.editDescription}>
        Your information will be visible to other Pro Pass holders through the Pro Area panel. You can
        update or edit it at any time using the 'Edit Info' button.
      </p>

      <div className={classes.editForm}>
        <div className={classes.profileImageSection}>
          <div className={classes.profileImage}>
            <img
              src={editForm.image?.thumbnails?.large?.url || userPlaceholder}
              alt={editForm.fullName || 'Profile preview'}
            />
          </div>
          <input
            type="file"
            accept="image/*"
            id="imageUpload"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          <button
            className={classes.changeImageButton}
            onClick={() => document.getElementById('imageUpload').click()}
          >
            Change profile picture
          </button>
        </div>

        <div className={classes.inputGroup}>
          <input
            type="text"
            placeholder="Full Name*"
            className={classes.editInput}
            value={editForm.fullName}
            onChange={(e) => setEditForm({ ...editForm, fullName: e.target.value })}
          />
          <input
            type="text"
            placeholder="Position*"
            className={classes.editInput}
            value={editForm.position}
            style={{ marginLeft: '16px' }}
            onChange={(e) => setEditForm({ ...editForm, position: e.target.value })}
          />
          <input
            type="text"
            placeholder="Company*"
            className={classes.editInput}
            value={editForm.company}
            onChange={(e) => setEditForm({ ...editForm, company: e.target.value })}
          />

          <button className={classes.submitButton} onClick={handleEditSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default ProfileStep;
