import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import useStyles from './styles';

import { formatNameForUrl } from '../../utils/helpers.js';
import { capitalizeEveryWord } from '../../utils/helpers.js';

const SpeakerCardSmall = ({ speaker, lang }) => {
  const classes = useStyles();

  return (
    <Link
      to={
        lang === 'pt'
          ? `/pt/orador/${formatNameForUrl(speaker.fields.Name)}`
          : `/speaker/${formatNameForUrl(speaker.fields.Name)}`
      }
      className={classes.speakerCard}
      key={speaker.id}
    >
      <div className={classes.row}>
        <div className={classes.imageContainer}>
          <div className={classes.ratio} >
            {speaker.fields.Images && speaker.fields.Images.length > 0 && (
              <img
                src={speaker.fields.Images[0].thumbnails.large.url}
                className={classes.image}
                alt={speaker.fields.Name}
                loading="lazy"
              />
            )}
          </div>
        </div>
        <div className={classes.speakerDetails}>
          <p className={cx(classes.speakerTitle, 'desktop')}>{capitalizeEveryWord(speaker.fields.Name)}</p>
          <p className={cx(classes.speakerAbout, 'country')}>{speaker.fields.Country}</p>
          <p className={classes.speakerAbout}>{speaker.fields[lang === 'pt' ? 'Role-PT' : 'Role']}</p>
        </div>
      </div>
      <div className={cx(classes.speakerDetails, 'desktop')}>
        <p className={classes.speakerTitle}>{capitalizeEveryWord(speaker.fields.Name)}</p>
      </div>
    </Link>
  );
};

export default SpeakerCardSmall;
