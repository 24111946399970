import React from 'react';
import useStylesProPanel from './styles';

const EmailStep = ({ email, setEmail, isLoading, onConfirm }) => {
  const classes = useStylesProPanel();

  return (
    <>
      <h2 className={classes.loginTitle}>Square Pro access</h2>
      <p className={classes.loginDescription}>
        Please enter the email address associated with your Pro Pass. We'll send you a 6-digit code to verify
        your access.
        <br />
        <br />A Pro Pass is required to access the Pro Area. If you don't have one yet, you can{' '}
        <a href="/tickets">get yours here.</a>
      </p>
      <div className={classes.inputContainer}>
        <input
          type="email"
          placeholder="Email*"
          className={classes.input}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <button
        className={classes.sendButton}
        onClick={onConfirm}
        disabled={isLoading}
      >
        {isLoading ? 'Sending...' : 'Send code'}
      </button>
    </>
  );
};

export default EmailStep;
