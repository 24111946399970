import { createUseStyles } from 'react-jss';

const useStylesProPanel = createUseStyles({
  bg: {
    background: "#F3F3F3"
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '240px 2rem 120px',
    margin: '0 auto',
    color: 'white',
    width: '83.33%',
    maxWidth: '960px',
    '@media (max-width: 1024px)': {
      width: "100%",
      padding: '150px 1.5rem 60px',
    },
  },
  introSection: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '3rem',
    color: 'white',
    textAlign: 'center',
    maxWidth: '1280px',
  },
  title: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '32px',
    marginBottom: '32px',
    alignSelf: 'center',
    color: 'var(--brown)',
  },
  description: {
    fontSize: '16px',
    lineHeight: '1.8',
    letterSpacing: '0.4px',
    maxWidth: "720px",
    margin: "0 auto",
    color: 'var(--brown)',
  },
  usersList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '32px',
    width: '100%',
    '@media (max-width: 1024px)': {
      gridTemplateColumns: '1fr',
    },
  },
  proCard: {
    display: 'flex',
    width: '100%',
    padding: '12px 22px',
    background: 'white',
    borderRadius: 14,
    boxShadow: '0px 4px 4px rgba(46, 41, 35, 0.20)',
    position: 'relative',
    '&.skeleton': {
      animation: '$pulse 1.5s ease-in-out infinite',
      '& $avatar, & $userName, & $userRole, & $userCompany': {
        background: '#f0f0f0',
      },
      '& $avatar': {
        border: 'none',
      },
      '& $userName': {
        width: '150px',
        height: '18px',
      },
      '& $userRole': {
        width: '100px',
        height: '14px',
      },
      '& $userCompany': {
        width: '120px',
        height: '14px',
      },
    },
  },
  avatar: {
    width: 80,
    height: 80,
    position: 'relative',
    marginRight: '24px',
    borderRadius: '50%',
    '& img': {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: '50%',
      border: '3px solid #39FF89',
    }
  },
  youBadge: {
    position: 'absolute',
    top: 0,
    right: -10,
    background: '#39FF89',
    borderRadius: 24,
    padding: '2px 10px',
    fontSize: 12,
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontWeight: 800,
    letterSpacing: '0.5px',
    color: '#2E2923',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  userName: {
    color: '#2E2923',
    fontSize: 18,
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontWeight: 800,
    letterSpacing: '0.5px',
    marginBottom: 8,
  },
  userRole: {
    color: 'rgba(46, 41, 35, 0.61)',
    fontSize: 14,
    fontFamily: 'Switzer',
    fontWeight: 400,
    letterSpacing: '0.25px',
    marginBottom: 4,
  },
  userCompany: {
    color: '#2E2923',
    fontSize: 14,
    fontFamily: 'Switzer',
    fontWeight: 600,
    letterSpacing: '0.25px',
  },
  editButton: {
    position: 'absolute',
    top: 13,
    right: 13,
    background: '#F3F3F3',
    borderRadius: 12,
    padding: '6px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '6px',
    cursor: 'pointer',
    '& span': {
      color: '#2E2923',
      fontSize: 12,
      lineHeight: "1em",
      fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
      fontWeight: 800,
      letterSpacing: '0.5px',
      paddingTop: '1px'
    },
    '& .dot': {
      width: 8,
      height: 8,
      background: '#2E2923',
      borderRadius: '50%',
    },
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#DADADA',
    }
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(46, 41, 35, 0.7)',
    zIndex: 999,
  },
  popup: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '566px',
    padding: '40px',
    background: '#F3F3F3',
    borderRadius: '24px',
    zIndex: 1000,
    '@media (max-width: 768px)': {
      width: '90%',
      padding: '24px',
    },
  },
  popupTitle: {
    color: '#2E2923',
    fontSize: '40px',
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontWeight: 800,
    lineHeight: '48px',
    letterSpacing: '0.8px',
    marginBottom: '24px',
  },
  popupDescription: {
    color: '#2E2923',
    fontSize: '18px',
    fontFamily: 'Switzer',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.25px',
    marginBottom: '32px',
  },
  inputContainer: {
    width: '100%',
    height: '48px',
    position: 'relative',
    marginBottom: '53px',
  },
  input: {
    width: '100%',
    height: '100%',
    background: 'white',
    borderRadius: '24px',
    border: 'none',
    padding: '12px 27px',
    fontSize: '18px',
    fontFamily: 'Switzer',
    '&::placeholder': {
      color: 'rgba(46, 41, 35, 0.39)',
    },
  },
  sendButton: {
    width: '163px',
    height: '40px',
    background: '#2E2923',
    borderRadius: '24px',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontWeight: 800,
    lineHeight: '32px',
    letterSpacing: '0.5px',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
  },
  errorMessage: {
    color: 'red',
    fontSize: '14px',
    marginTop: '8px',
    textAlign: 'left',
  },
  '@keyframes pulse': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0.5 },
    '100%': { opacity: 1 },
  },

  editTitle: {
    fontSize: '40px',
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontWeight: 800,
    lineHeight: '48px',
    letterSpacing: '0.8px',
    color: '#2E2923',
    marginBottom: '12px',
  },
  editDescription: {
    fontSize: '16px',
    fontFamily: 'Switzer',
    lineHeight: '1.4em',
    letterSpacing: '0.25px',
    color: '#2E2923',
    marginBottom: '32px',
  },
  editForm: {
    display: 'flex',
    flexDirection: 'row',
    gap: '32px',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem'
  },
  editInput: {
    width: '320px',
    height: '40px',
    background: 'white',
    borderRadius: '24px',
    padding: '8px 24px',
    fontSize: '18px',
    fontFamily: 'Switzer',
    border: '1px solid rgba(46, 41, 35, 0.39)',
  },
  submitButton: {
    width: 150,
    height: '40px',
    background: '#2E2923',
    borderRadius: '24px',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontWeight: 800,
    cursor: 'pointer',
    marginTop: "0.75rem"
  },
  profileImageSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  profileImage: {
    width: 118.15,
    height: 120,
    position: 'relative',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: 80,
      border: '3px solid #39FF89',
    },
    '& .background': {
      width: 118.15,
      height: 118.15,
      left: 0,
      top: 0,
      position: 'absolute',
      background: '#2E2923',
      borderRadius: 80
    }
  },
  changeImageButton: {
    background: 'none',
    border: 'none',
    textDecoration: 'underline',
    color: '#2E2923',
    fontSize: '14px',
    fontFamily: 'Switzer',
    cursor: 'pointer',
  }
});

export default useStylesProPanel;
