import React from 'react';
import useStyles from './styles.js';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import SpeakerCard from '../speaker-card';
import { airtableApi } from '../../services/api';


const SpeakerList = (props) => {
  const classes = useStyles();

  const location = useLocation();
  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  const { data, error, isLoading } = useQuery({
    queryKey: ['speakers'],
    queryFn: airtableApi.getSpeakers,
  });

  const sortedData = data ? [...data].sort((a, b) => a.fields.Name.localeCompare(b.fields.Name)) : [];

  return (
    <div className={classes.speakersList}>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error loading data: {error.message}</p>}
      {!isLoading && !error && sortedData.map((speaker) => (
        <SpeakerCard
          key={speaker.id}
          speaker={speaker}
          lang={lang}
        />
      ))}
    </div>
  );
};

export default SpeakerList;
