import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  main: {
    backgroundColor: 'var(--main-background-color)',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '240px 40px 120px 40px',
    width: '83.33%',
    maxWidth: '1280px',
    margin: '0 auto',
    color: 'white',
    height: 'auto',
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    gap: '4rem',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    maxWidth: '400px',
  },
  RowSocial: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  column: {
    width: '61.8%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '16px',
    position: 'relative',
  },
  ratio: {
    paddingBottom: '100%',
    position: 'relative',
    border: '2px solid var(--green)',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  selectedbyImage: {
    maxWidth: '97px',
    margin: '1rem 0',
  },
  date: {
    fontSize: '24px',
    color: 'var(--green)',
    fontWeight: '600',
    marginBottom: '8px',
  },
  title: {
    fontFamily: 'Switzer',
    fontSize: '54px',
    fontWeight: '600',
    lineHeight: '1.2em',
    letterSpacing: '1px',
    color: 'var(--green)',
  },
  description: {
    fontSize: '18px',
    lineHeight: '1.8em',
    color: 'white',
  },
  selectedby: {
    fontSize: '18px',
    color: 'var(--red)',
    marginTop: '1rem',
  },
  embedPlayer: {
    width: '100%',
  },
  socials: {
    width: 'auto',
    height: '24px',
    marginRight: '16px',
    cursor: 'pointer',
  },
  embed: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '2rem 0',
    borderRadius: '20px',
    overflow: 'hidden',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    marginTop: '1rem',
  },
  tag: {
    width: 'auto',
    padding: '4px 8px 4px 0',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    '&.type': {
      padding: '4px 8px',
      backgroundColor: '#40320D',
    },
  },
  tagIcon: {
    width: 'auto',
    height: '16px',
    marginRight: '12px',
    marginBottom: '0px',
  },
  tagText: {
    fontFamily: 'Switzer',
    fontSize: '24px',
    fontWeight: 600,
    color: '#39FF89',
    margin: '-1px 0 0 0',
    letterSpacing: '0.5px',
    '& span': {
      color: '#fff',
    },
  },
  type: {
    fontFamily: 'Switzer',
    fontSize: '18px',
    fontWeight: 600,
    color: '#fff',
    textTransform: 'uppercase',
    margin: 0,
    letterSpacing: '0.5px',
  },
  city: {
    fontFamily: 'Switzer',
    fontSize: '24px',
    fontWeight: 600,
    color: '#fff',
    margin: 'auto 0',
    textTransform: 'uppercase',
  },
  colImage: {
    width: '38.2%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  speakersTitle: {
    fontSize: '24px',
    fontWeight: '600',
    color: 'var(--green)',
  },
  speakerIcon: {
    width: 'auto',
    height: '20px',
    margin: '0 0 auto 12px',
  },
  '@media (max-width: 1024px)': {
    container: {
      width: '100%',
      padding: '240px 24px 80px 24px',
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
    },
    column: {
      width: '100%',
    },
    colImage: {
      width: '100%',
      alignItems: 'flex-start',
      paddingTop: '0',
    },
    sticky: {
      position: 'relative',
      top: '0',
    },
  },
  '@media (max-width: 768px)': {
    title: {
      fontSize: '48px',
      lineHeight: '56px',
    },
    description: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    date: {
      fontSize: '20px',
    },
    speakerTitle: {
      fontSize: '32px',
    },
    speaker: {
      fontSize: '20px',
    },
    speakerIcon: {
      height: '16px',
    },
  },
  '@media (max-width: 480px)': {
    container: {
      padding: '160px 16px 80px 16px',
    },
    title: {
      fontSize: '32px',
      lineHeight: '40px',
    },
    details: {
      flexWrap: 'wrap',
      marginTop: '2px',
      marginBottom: '8px',
    },
    description: {
      fontSize: '16px',
      lineHeight: '32px',
    },
    tagText: {
      fontSize: '16px',
    },
    speaker: {
      fontSize: '16px',
    },
    speakerTitle: {
      fontSize: '24px',
    },
    speakerIcon: {
      height: '12px',
    },
  },
});

export default useStyles;
