/**
 * Normalizes a string by removing accents/diacritics
 *
 * @param {string} str - The string to normalize
 * @returns {string} - Normalized string without diacritics
 */
export const normalizeString = (str) => {
  if (!str) return '';

  return str
    .toLowerCase()
    .normalize('NFD') // Decomposes accented characters into base character + diacritical mark
    .replace(/[\u0300-\u036f]/g, ''); // Removes the diacritical marks
};

/**
 * Formats a string to be URL-friendly by:
 *
 * @param {string} name - The string to be formatted
 * @returns {string} - URL-friendly string
 */
export const formatNameForUrl = (name) => {
  if (!name) return '';

  return name
    .toLowerCase()
    .replace(/\s+/g, '-');
};

/**
 * Capitalizes the first letter of every word in a string
 *
 * @param {string} str - The string to capitalize
 * @returns {string} - String with first letter of each word capitalized
 */
export const capitalizeEveryWord = (str) => {
  if (!str) return '';

  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};


/**
 * Sets a cookie with a specified expiration time in days
 *
 * @param {string} name - The name of the cookie
 * @param {string} value - The value to store
 * @param {number} days - Number of days until expiration
 */
export const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

/**
 * Gets a cookie value by name
 *
 * @param {string} name - The name of the cookie to retrieve
 * @returns {string|null} - The cookie value or null if not found
 */
export const getCookie = (name) => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.startsWith(cookieName)) {
      return cookie.substring(cookieName.length);
    }
  }
  return null;
};
