import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { airtableApi } from '../../services/api';
import useStyles from './styles';
import cx from 'classnames';
import SpeakerCardSmall from 'components/speaker-card-small';
import Header from 'components/common/header';
import Footer from 'components/common/footer';
import calendar from 'assets/icons/calendar_white.svg';
import poi from 'assets/icons/poi_white.svg';
import { normalizeString } from '../../utils/helpers';

const fetchEvent = async () => {
  const [events, speakers] = await Promise.all([airtableApi.getSchedule(), airtableApi.getSpeakers()]);
  return { events, speakers };
};

const EventPage = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { name } = useParams();
  const lang = pathname.startsWith('/pt') ? 'pt' : 'en';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const formattedName = normalizeString(name.replace(/-/g, ' '));

  const { data, error, isLoading } = useQuery({
    queryKey: ['event'],
    queryFn: fetchEvent,
  });

  return (
    <>
      <Header conference lang={lang} />
      <main className={classes.main}>
        <div className={classes.container}>
          {isLoading && <p>Loading event details...</p>}

          {error && <p>Error loading event details: {error.message}</p>}

          {!isLoading &&
            !error &&
            data &&
            (() => {
              const event = data.events.find((event) => normalizeString(event.fields.Name) === formattedName);

              if (!event) {
                return <p>No event found with the name: {formattedName}</p>;
              }

              // Get speakers data if event has speaker references
              const eventSpeakers = event?.fields['Speakers REF']
                ? data.speakers.filter((speaker) => event.fields['Speakers REF'].includes(speaker.id))
                : [];

              return (
                <div className={classes.row}>
                  <div className={classes.column}>
                    <div className={cx(classes.tag, 'type')}>
                      <p className={classes.type}>{event.fields['Name (from Tags)']}</p>
                    </div>
                    <h1 className={classes.title}>{lang === 'en' ? event.fields.Title : event.fields['Title PT']}</h1>
                    <div className={classes.details}>
                      <div className={classes.tag}>
                        <img src={calendar} alt="poi" className={classes.tagIcon} />
                        <p className={classes.tagText}>{event.fields['Name (from Day)']}</p>
                      </div>
                      <div className={classes.tag}>
                        <img src={poi} alt="poi" className={classes.tagIcon} />
                        <p className={classes.tagText}>{event.fields['Name (from Venue)']}</p>
                      </div>
                      <p className={classes.city}>{event.fields['Name (from City)']}</p>
                    </div>
                    <div className={classes.description}>
                      <p>{lang === 'en' ? event.fields.Description : event.fields['Description PT']}</p>
                    </div>
                  </div>
                  <div className={classes.colImage}>
                    <div className={classes.list}>
                      <p className={classes.speakersTitle}>{lang === 'en' ? 'SPEAKERS' : 'ORADORES'}</p>
                      {event.fields['Speakers REF']
                        ? eventSpeakers.map((speaker) => (
                          <SpeakerCardSmall key={speaker.id} speaker={speaker} lang={lang} />
                        ))
                        : event.fields.Speakers || 'TBC'}
                    </div>
                  </div>
                </div>
              );
            })()}
        </div>
      </main>
      <Footer lang={lang} />
    </>
  );
};

export default EventPage;
