import { createUseStyles } from 'react-jss';

const useStylesConference = createUseStyles({
  introSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: '3rem',
    color: 'white',
    textAlign: 'center',
    maxWidth: '1280px',
  },
  title: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '32px',
    marginBottom: '32px',
    alignSelf: 'center',
    color: 'var(--green)',
  },
  description: {
    fontSize: '16px',
    lineHeight: '1.8',
    letterSpacing: '0.4px',
    maxWidth: '720px',
    margin: '0 auto',
  },
  timeFilterButtonText: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  stickyMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    position: 'unset',
    zIndex: 21,
    '@media (max-width: 780px)': {
      position: 'sticky',
      top: '110px',
    },
  },

  tabMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: '3rem',
    gap: '2rem',
    '@media (max-width: 780px)': {
      paddingBottom: '24px',
    },
  },
  tabMenuItem: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    position: 'relative',
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    textTransform: 'uppercase',
    fontSize: '22px',
    letterSpacing: '0.4px',
    color: 'var(--brown)',
    '&.current': {
      color: 'var(--red)',
    },
    '@media (max-width: 780px)': {
      fontSize: '18px',
    },
  },
  current: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    height: '4px',
    backgroundColor: 'var(--red)',
  },
  filtersContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    alignItems: 'center',
    gap: '1rem',
    '@media (max-width: 780px)': {
      flexDirection: 'column',
    },
  },
  dateSelectorContainer: {
    display: 'flex',
    gap: '1.5rem',
    position: 'relative',
  },
  dateItem: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '18px',
    color: 'var(--brown)',
    cursor: 'pointer',
    position: 'relative',
    padding: '4px 0',
    '@media (max-width: 480px)': {
      fontSize: '16px',
    },
  },
  active: {
    color: 'var(--red)',
  },
  activeLine: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: 'var(--red)',
  },
  '@media (max-width: 1024px)': {
    introSection: {
      paddingTop: '3rem',
    },
  },
  cityFilterContainer: {
    position: 'relative',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cityDropdownButton: {
    padding: '4px 14px',
    cursor: 'pointer',
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '18px',
    color: 'var(--brown)',
    '&:hover': {
      background: 'var(--red)',
      color: 'white'
    },
    '@media (max-width: 480px)': {
      fontSize: '16px'
    },
  },
  cityDropdownMenu: {
    position: 'absolute',
    top: '100%',
    right: 0,
    width: '100%',
    minWidth: '192px',
    background: 'white',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    zIndex: 1000,
  },
  cityOption: {
    padding: '8px',
    cursor: 'pointer',
    color: 'var(--red)',
    fontFamily: 'Switzer',
    fontWeight: 600,
    fontSize: '12px',
    borderBottom: '1px solid var(--red)',
    '&:hover': {
      background: 'var(--red)',
      color: 'white',
    },
  },
  activeCity: {
    background: 'var(--red)',
    color: 'white',
  },
  timeAndCityFilterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timeFilterButton: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '18px',
    color: 'var(--brown)',
    cursor: 'pointer',
    position: 'relative',
    padding: '4px 0.75rem',
    '&:hover': {
      color: 'var(--red)',
    },
    '@media (max-width: 480px)': {
      fontSize: '16px'
    },
  },
  activeTimeFilter: {
    color: 'var(--red)',
  },
  timeFilterLine: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: 'var(--red)',
  },
});

export default useStylesConference;
