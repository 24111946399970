import { createUseStyles } from 'react-jss';

const useStylesConference = createUseStyles({

  introSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: '3rem',
    color: 'white',
    textAlign: 'center',
    maxWidth: '1280px',
  },

  title: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '32px',
    marginBottom: '32px',
    alignSelf: 'center',
    color: 'var(--green)',
  },

  description: {
    fontSize: '16px',
    lineHeight: '1.8',
    letterSpacing: '0.4px',
    maxWidth: "720px",
    margin: "0 auto"
  },

  tabMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '40px 0',
    gap: '2rem'
  },

  tabMenuItem: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    position: 'relative',
    color: 'white',
    minWidth: '125px',
    alignItems: 'flex-start',
    '&.current': {
      color: 'var(--green)',
    },

    '& p': {
      fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
      textTransform: 'uppercase',
      fontSize: '18px',
      letterSpacing: '0.4px',
    },
  },

  current: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    height: '4px',
    backgroundColor: 'var(--green)',
  },

  '@media (max-width: 1024px)': {
    introSection: {
      paddingTop: "3rem"
    },

  },

});

export default useStylesConference;
