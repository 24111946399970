import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  list: {
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '24px',
    padding: '0 20px',
    marginBottom: '6rem',
    color: 'white',
    '@media (max-width: 1024px)': {
      padding: '0px',
    },
    '@media (max-width: 780px)': {
      gridTemplateColumns: '1fr',
    },
  },
  event: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    transition: 'all 0.3s ease-in-out',
    padding: '1.5rem',
    paddingBottom: '2rem',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    color: '#29220F',
    width: '100%',
    '&:hover': {
      backgroundColor: '#39FF89',
      color: '#fff',
      overflow: 'visible',
      textDecoration: 'none',
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  tag: {
    backgroundColor: '#2E2923',
    padding: '4px 8px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  tagIcon: {
    width: 'auto',
    height: '12px',
    marginRight: '6px',
    marginBottom: '0px',
  },
  tagText: {
    fontFamily: 'Switzer',
    fontSize: '16px',
    fontWeight: 600,
    color: '#39FF89',
    margin: '-1px 0 0 0',
    letterSpacing: '0.5px',
    '& span': {
      color: '#fff',
    },
  },
  type: {
    fontFamily: 'Switzer',
    fontSize: '12px',
    fontWeight: 600,
    color: '#fff',
    textTransform: 'uppercase',
    margin: 0,
    letterSpacing: '0.5px',
  },
  eventTitle: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '24px',
    margin: '24px 0 12px 0',
    lineHeight: '1.2',
  },
  speakersRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    paddingTop: '1rem',
    marginTop: 'auto',
    position: 'relative',
    zIndex: 2,
  },
  speakers: {
    fontFamily: 'Switzer',
    fontSize: '16px',
    fontWeight: 600,
    color: '#2E2923',
    letterSpacing: '0.5px',
    paddingTop: '0.75rem',
    paddingLeft: '0.75rem',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  speakerName: {
    whiteSpace: 'nowrap',
  },
  speakerIcon: {
    width: 'auto',
    height: '20px',
    margin: '0 0 auto 12px',
  },
  city: {
    fontFamily: 'Switzer',
    fontSize: '72px',
    color: '#F3F3F3',
    lineHeight: '72px',
    position: 'absolute',
    bottom: '-9px',
    left: '-3px',
    margin: 0,
  },
});

export default useStyles;
