import React, { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useStylesProPanel from './styles';
import useStyles from '../styles';
import { airtableApi } from 'services/api';
import { useQuery } from '@tanstack/react-query';  // Add this import
import { setCookie, getCookie } from 'utils/helpers';


import Header from 'components/common/header';
import Footer from 'components/common/footer';

const ProPanel = () => {
  const classesGlobal = useStyles();
  const classes = useStylesProPanel();

  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { data: usersData, error } = useQuery({
    queryKey: ['users'],
    queryFn: airtableApi.getUsers,
    onSuccess: (data) => {
      console.log('User data received:', data);
    },
    onError: (err) => {
      console.error('Error fetching users:', err);
    },
  });

  const validEmails = useMemo(() => {
    return usersData?.map(user => user.fields.Email) || [];
  }, [usersData]);

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editForm, setEditForm] = useState({
    fullName: '',
    position: '',
    company: '',
    image: null
  });

  const handleEditSubmit = () => {
    // TODO: Implement API call to update user info
    setShowEditPopup(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditForm(prev => ({
        ...prev,
        image: {
          url: URL.createObjectURL(file),
          thumbnails: { large: { url: URL.createObjectURL(file) } },
          file  // Store the file directly in the image object
        }
      }));
    }
  };

  const handleEmailSubmit = () => {
    const isEmailValid = validEmails.includes(email);

    if (isEmailValid) {
      setCookie('userEmail', email, 1);
      setShowPopup(false);
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 750);
    } else {
      setErrorMessage('Email not found. Please try again.');
    }
  };


  // Add useEffect to check localStorage on mount
  useEffect(() => {
    const savedEmail = getCookie('userEmail');
    if (savedEmail) {
      setEmail(savedEmail);
      setShowPopup(false);
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 750);
    } else {
      setShowPopup(true);
    }
  }, []);


  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';


  return (
    <div className={classesGlobal.App}>
      <Header proPanel lang={lang} isSingle />
      {showPopup && (
        <div className={classes.overlay} >
          <div className={classes.popup}>
            <h2 className={classes.popupTitle}>Square Pro access</h2>
            <p className={classes.popupDescription}>
              Please enter the email used to purchase the Pro Pass to access the Pro Panel
            </p>
            <div className={classes.inputContainer}>
              <input
                type="email"
                placeholder="Email*"
                className={classes.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
            </div>
            <button
              className={classes.sendButton}
              onClick={handleEmailSubmit}
            >
              Confirm
            </button>
          </div>
        </div>
      )
      }
      <main className={`${classesGlobal.main} ${classes.bg}`}>
        {showEditPopup && (
          <div className={classes.overlay}>
            <div className={classes.popup}>
              <h1 className={classes.editTitle}>Edit your Square profile</h1>
              <p className={classes.editDescription}>
                Your information will be visible to other Pro Pass holders through the Pro Area panel.
                You can update or edit it at any time using the 'Edit Info' button.
              </p>

              <div className={classes.editForm}>
                <div className={classes.profileImageSection}>
                  <div className={classes.profileImage}>
                    <img
                      src={editForm.image?.thumbnails?.large?.url || "https://via.placeholder.com/80x80"}
                      alt={editForm.fullName || "Profile preview"}
                    />
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    id="imageUpload"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                  />
                  <button
                    className={classes.changeImageButton}
                    onClick={() => document.getElementById('imageUpload').click()}
                  >
                    Change profile picture
                  </button>
                </div>

                <div className={classes.inputGroup}>
                  <input
                    type="text"
                    placeholder="Full Name*"
                    className={classes.editInput}
                    value={editForm.fullName}
                    onChange={(e) => setEditForm({ ...editForm, fullName: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="Position*"
                    className={classes.editInput}
                    value={editForm.position}
                    onChange={(e) => setEditForm({ ...editForm, position: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="Company*"
                    className={classes.editInput}
                    value={editForm.company}
                    onChange={(e) => setEditForm({ ...editForm, company: e.target.value })}
                  />

                  <button className={classes.submitButton} onClick={handleEditSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {!showPopup && (
          <div className={classes.container}>

            <div className={classes.introSection}>
              <h1 className={classes.title}>SQUARE PRO PANEL</h1>
              {lang === 'en' ? (
                <p className={classes.description}>
                  Welcome {usersData?.find(user => user.fields.Email === email)?.fields.Name?.split(' ')[0] || ''}, here you can check all of Pro Users attending the event.<br/>
                  By 7 of January you will able to download app and network with pro users.
                </p>
              ) : (
                <p className={classes.description}>
                  Utilizadores Pro a participar no evento:
                </p>
              )}
            </div>

            {isLoading ? (
              <div className={classes.usersList}>
                {usersData?.map((_, index) => (
                  <div key={index} className={`${classes.proCard} skeleton`}>
                    <div className={classes.avatar} />
                    <div className={classes.userInfo}>
                      <div className={classes.userName} />
                      <div className={classes.userRole} />
                      <div className={classes.userCompany} />
                    </div>
                  </div>
                ))}
              </div>
            ) : error ? (
              <div>Error loading Users</div>
            ) : (
              <div className={classes.usersList}>
                {usersData?.map((user, index) => (
                  <div key={user.id || index} className={classes.proCard}>
                    {console.log(user)}
                    <div className={classes.avatar}>
                      <img src={user.fields.Image?.[0].thumbnails?.large?.url || "https://via.placeholder.com/80x80"} alt={user.fields.Name} />
                      {user.fields.Email === email && <div className={classes.youBadge}>You</div>}
                    </div>
                    <div className={classes.userInfo}>
                      <div className={classes.userName}>{user.fields.Name}</div>
                      <div className={classes.userRole}>{user.fields.Role}</div>
                      <div className={classes.userCompany}>{user.fields.Company}</div>
                    </div>
                    {user.fields.Email === email && (
                      <div
                        className={classes.editButton}
                        onClick={() => {
                          setShowEditPopup(true);
                          setEditForm({
                            fullName: user.fields.Name || '',
                            position: user.fields.Role || '',
                            company: user.fields.Company || '',
                            image: user.fields.Image?.[0] || null
                          });
                        }}
                      >
                        <div className="dot"></div>
                        <span>EDIT INFO</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </main>
      <Footer lang={lang} />
    </div >
  );
};

export default ProPanel;
