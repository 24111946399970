import React, { useEffect } from 'react';
import useStyles from './styles';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { airtableApi } from '../../services/api';
import { formatNameForUrl } from 'utils/helpers';

import Header from 'components/common/header';
import Footer from 'components/common/footer';

const PartnerDetailPage = () => {
  const { pathname } = useLocation();
  const { name } = useParams();
  const formattedName = name.replace(/-/g, ' ').toUpperCase();
  const lang = pathname.startsWith('/pt') ? 'pt' : 'en';
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { data, error, isLoading } = useQuery({
    queryKey: ['ProgrammingPartners'],
    queryFn: airtableApi.getProgrammingPartners,
  });

  // Finding the partner matching the formatted name
  const partner = data?.find((partner) => partner.fields.Name.toUpperCase() === formattedName);

  return (
    <>
      <Header partners lang={lang} />
      <main className={classes.main}>
        <div className={classes.container}>
          {isLoading && <p>Loading partner details...</p>}
          {error && <p>Error loading partner details: {error.message}</p>}

          {!isLoading && !error && (
            <>
              {!partner ? (
                <p>No partner found with the name: {formattedName}</p>
              ) : (
                <>
                  <div className={classes.row}>
                    <div className={classes.column}>
                      <h1 className={classes.title}>
                        {partner.fields.Name}
                      </h1>

                      <div className={classes.description}>
                        {lang === 'en' ? (
                          <p> {partner.fields.Description} <br /><br /></p>
                        ) : (
                          <p> {partner.fields["Description PT"]} <br /><br /></p>
                        )}
                      </div>

                      {partner.fields["Name (from Artistas)"] && (
                        <>
                          {lang === 'en' ? (
                            <p className={classes.selectedby}>Artist Selected: </p>
                          ) : (
                            <p className={classes.selectedby}>Artista Selecionado:</p>
                          )}
                          <Link
                            to={
                              lang === 'en'
                                ? `/artist/${formatNameForUrl(String(partner.fields["Name (from Artistas)"]))}`
                                : `/pt/artista/${formatNameForUrl(String(partner.fields["Name (from Artistas)"]))}`
                            }
                            className={classes.rowArtist}
                          >
                            {partner.fields["Images (from Artistas)"] && partner.fields["Images (from Artistas)"].length > 0 && (
                              <div className={classes.colArtistImage}>
                                <div className={classes.ratio}>
                                  <img
                                    className={classes.imageArtist}
                                    src={
                                      partner.fields["Images (from Artistas)"][1]?.thumbnails.large.url || partner.fields["Images (from Artistas)"][0]?.thumbnails.large.url
                                    }
                                    alt={partner.fields.Name}
                                  />
                                </div>
                              </div>
                            )}

                            <div className={classes.colArtist}>
                              <p className={classes.artistTitle}>
                                {partner.fields["Name (from Artistas)"] || "No artist available"}
                              </p>
                              <span className={classes.button}>
                                {lang === 'en' ? "See Artist" : "Ver Artista"}
                              </span>
                            </div>
                          </Link>
                        </>
                      )}
                    </div>

                    <div className={classes.colImage}>
                      {partner.fields.Image && partner.fields.Image.length > 0 && (
                        <img
                          className={classes.image}
                          src={
                            partner.fields.Image[1]?.thumbnails?.large?.url || partner.fields.Image[0]?.thumbnails?.large?.url
                          }
                          alt={partner.fields.Name}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </main>
      <Footer lang={lang} />
    </>
  );
};

export default PartnerDetailPage;
