import axios from 'axios';

// You can create a base axios instance with common configuration
const api = axios.create({
  baseURL: 'https://oyster-app-jiunm.ondigitalocean.app',
});

// Group reylated API calls into objects/namespaces
export const airtableApi = {
  // Get all artists
  getArtists: async () => {
    const response = await api.get('/airtable-data', {
      params: {
        table_name: 'Artistas',
      },
    });
    return response.data;
  },

  // Get all schedule events
  getSchedule: async () => {
    const response = await api.get('/airtable-data', {
      params: {
        table_name: 'Schedule',
      },
    });
    return response.data;
  },

  // Get all speakers
  getSpeakers: async () => {
    const response = await api.get('/airtable-data', {
      params: {
        table_name: 'Speakers',
      },
    });
    return response.data;
  },

  // Get homepage data
  getHomepage: async () => {
    const response = await api.get('/airtable-data', {
      params: {
        table_name: 'Homepage',
      },
    });
    return response.data;
  },

  // Get programming partners
  getProgrammingPartners: async () => {
    const response = await api.get('/airtable-data', {
      params: {
        table_name: 'Parceiros de programação',
      },
    });
    return response.data;
  },

  // Get news data
  getNews: async () => {
    const response = await api.get('/airtable-data', {
      params: {
        table_name: 'News',
      },
    });
    return response.data;
  },

  // Get partners data
  getPartners: async () => {
    const response = await api.get('/airtable-data', {
      params: {
        table_name: 'Partners',
      },
    });
    return response.data;
  },

  getUsers: async () => {
    const response = await api.get('/airtable-data', {
      params: {
        table_name: 'Users',
      },
    });
    return response.data;
  },

  // Add new verification code endpoint
  sendVerificationCode: async (email) => {
    const response = await api.post('/send-verification', {
      email: email
    });
    return response.data;
  }

  // You can add more artist-related API calls here
  // getById: async (id) => { ... },
  // getByName: async (name) => { ... },
};

// You can add more API namespaces here
// export const eventsApi = { ... }
// export const venuesApi = { ... }
