import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: "76%",
    maxWidth: '1280px',
    margin: '0 auto',
  },
  speakersList: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '24px',
    flexWrap: 'wrap',
    marginBottom: '6rem',
    '@media (max-width: 1024px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@media (max-width: 768px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
    }
  },
  speakers: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '18px 18px 40px 18px',
    width: '33.33%',
    color: 'var(--green)',
    '& img': {
      width: '100%',
      objectFit: 'cover',
    },
    '& img.big': {
      height: '4.2cm',
      padding: 0,
    },
  },
  imageContainer: {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  detailButton: {
    marginTop: '10px',
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  '@media (max-width: 1024px)': {
    container: {
      width: "100%",
    },
    speakersList: {
      gap: '2rem',
    },
    speakers: {
      width: "100%"
    },
    speakersSection: {
      padding: '4rem 1rem',
    },
  },
});

export default useStyles;
