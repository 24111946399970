import React from 'react';
import { useLocation } from 'react-router-dom';
import useStyles from './styles';
import LineupCard from 'components/lineup-card';

const LineupList = ({
  selectedDate,
  selectedCity,
  timeFilter,
  scheduleData = [],
  isLoading,
  error
}) => {
  const classes = useStyles();
  const location = useLocation();
  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  const events = scheduleData
    .filter((item) => item.fields['Name (from Type)']?.includes('Lineup'))
    .filter((item) => {
      const dateArray = item.fields['Name (from Day)'] || [];
      const matches = selectedDate === null || dateArray.includes(selectedDate);
      return matches;
    })
    .filter((item) => {
      const cityArray = item.fields['Name (from City)'] || [];
      return selectedCity === null || cityArray.includes(selectedCity);
    })
    .filter((item) => {
      const period = item.fields['Name (from Period)'] || '';
      if (!period) return timeFilter === null;
      if (timeFilter === null) return true;
      return period.toString().toLowerCase().includes(timeFilter);
    });


  return (
    <div className={classes.list}>
      {isLoading && (
        <>
          <LineupCard isLoading />
          <LineupCard isLoading />
          <LineupCard isLoading />
          <LineupCard isLoading />
          <LineupCard isLoading />
          <LineupCard isLoading />
          <LineupCard isLoading />
          <LineupCard isLoading />
        </>
      )}
      {!isLoading && error && <div>Error: {error.message}</div>}

      {!isLoading && !error && events.length === 0 && (
        <div className={classes.noResults}>
          {lang === 'en' ? 'No events with this selection.' : 'Sem eventos com esta seleção.'}
        </div>
      )}

      {!isLoading &&
        !error &&
        events.map((event, index) => (
          <LineupCard
            key={index}
            event={event}
            lang={lang}
          />
        ))}
    </div>
  );
};

export default LineupList;
