import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '240px 2rem 120px',
    margin: '0 auto',
    color: 'white',
    width: '83.33%',
    maxWidth: '960px',
  },

  newsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '32px',
    width: '100%',
    '& > *:only-child': {
      gridColumn: '1 / -1',
      justifySelf: 'center',
    },
  },

  newsItem: {
    display: 'flex',
    flexDirection: 'column',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: "32px;",
    alignItems: 'flex-end'
  },

  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  image: {
    width: '45.2%',
    height: 'auto',
    objectFit: 'cover',
    border: '2px solid var(--green)',
  },

  date: {
    fontSize: '20px',
    color: 'var(--green)',
    fontWeight: '600',
    marginBottom: '4px',
  },

  title: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '24px',
    lineHeight: '1.2em',
    letterSpacing: '0.6px',
    marginBottom: '8px',
  },

  description: {
    fontSize: '14px',
    lineHeight: '24px',
    overflow: 'hidden',
    height: '100px', // defines the max height of the box
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 4, // limits to 4 lines, adjust according to your needs
    textOverflow: 'ellipsis'
  },

  // Add responsive styles for smaller screens
  '@media (max-width: 768px)': {
    container: {
      width: "100%",
      padding: '150px 1.5rem 60px',
    },
    newsGrid: {
      gridTemplateColumns: '1fr',
    },
    image: {
      width: '100%',
    },
    row: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    column: {
      width: '100%',
      marginTop: '16px',
    },
  },
});

export default useStyles;
