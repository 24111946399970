import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { airtableApi } from 'services/api';
import Header from 'components/common/header';
import Footer from 'components/common/footer';
import NewsList from 'components/news-list';
import useStyles from '../styles';

const News = () => {
  const globalClasses = useStyles();
  const { pathname } = useLocation();

  const lang = pathname.startsWith('/pt') ? 'pt' : 'en';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { data, error, isLoading } = useQuery({
    queryKey: ['news'],
    queryFn: airtableApi.getNews,
    select: (data) => data.filter((item) => item.fields.Status !== 'In progress'),
  });

  return (
    <div className={globalClasses.App}>
      <Header news lang={lang} error={error} isLoading={isLoading} />
      <main className={globalClasses.main}>
        <NewsList lang={lang} data={data} error={error} isLoading={isLoading} />
      </main>
      <Footer lang={lang} />
    </div>
  );
};

export default News;
