import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  event: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: '#F3F3F3',
    transition: 'all 0.3s ease-in-out',
    padding: '1.5rem',
    paddingBottom: '2rem',
    cursor: 'pointer',
    overflow: 'hidden',
    color: '#29220F',
    alignItems: 'flex-start',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'var(--red)',
      color: '#fff',
      overflow: 'visible',
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    gap: '0.75rem',
  },
  tag: {
    backgroundColor: '#fff',
    padding: '4px 8px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 20,
    '&.type': {
      marginLeft: 'auto',
    },
  },
  tagIcon: {
    width: 'auto',
    height: '12px',
    marginRight: '6px',
    marginBottom: '0px',
  },
  tagText: {
    fontFamily: 'Switzer',
    fontSize: '16px',
    fontWeight: 600,
    color: 'var(--red)',
    margin: '-1px 0 0 0',
    letterSpacing: '0.5px',
    '& span': {
      color: '#fff',
    },
  },
  type: {
    fontFamily: 'Switzer',
    fontSize: '12px',
    fontWeight: 600,
    color: 'var(--brown)',
    textTransform: 'uppercase',
    margin: 0,
    letterSpacing: '0.5px',
  },
  eventTitle: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '24px 0 12px 0',
    lineHeight: '1.2em',
    position: 'relative',
    zIndex: 2,
  },
  city: {
    fontFamily: 'Switzer',
    fontSize: '60px',
    color: '#FFE1DF',
    lineHeight: '1em',
    position: 'absolute',
    bottom: '-8px',
    right: '-2px',
  },
  loading: {
    pointerEvents: 'none',
  },
  skeleton: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    animation: '$pulse 1.5s ease-in-out infinite',
    '&.tagIcon': {
      width: 20,
      height: 20,
      borderRadius: '50%',
    },
    '&.tagText': {
      width: 80,
      height: 16,
      borderRadius: 4,
    },
    '&.type': {
      width: 60,
      height: 16,
      borderRadius: 4,
    },
    '&.eventTitle': {
      width: '80%',
      height: 24,
      borderRadius: 4,
      margin: '12px 0',
    },
    '&.city': {
      width: 100,
      height: 16,
      borderRadius: 4,
    },
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.4,
    },
    '100%': {
      opacity: 1,
    },
  },
});

export default useStyles;
