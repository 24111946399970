import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { airtableApi } from '../../services/api';
import useStyles from './styles.js';
import Button from 'components/button/index.js';
import PropTypes from 'prop-types';
import SpeakerCard from 'components/speaker-card';

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const SpeakersList = ({ lang }) => {
  const classes = useStyles();

  const { data, error, isLoading } = useQuery({
    queryKey: ['speakers'],
    queryFn: airtableApi.getSpeakers,
  });

  const shuffledSpeakers = data ? shuffleArray(data) : [];

  return (
    <section className={classes.speakersSection}>
      {lang === 'en' && <p className={classes.speakersTitleXL}>Confirmed Speakers</p>}
      {lang === 'pt' && <p className={classes.speakersTitleXL}>Oradores confirmados</p>}

      <div className={classes.maxWidth}>
        {isLoading && <p>Loading...</p>}
        {error && <p>Error loading data: {error.message}</p>}

        {!isLoading && !error && (
          <>
            <div className={classes.speakersList}>
              {shuffledSpeakers
                .slice(0, 3)
                .map((speaker) => (
                  <SpeakerCard
                    key={speaker.id}
                    speaker={speaker}
                    lang={lang}
                  />
                ))}
            </div>

            <Link to="/conference" className="no-underline">
              <Button background="green" color="brown" fontSize="24px">
                {lang === 'en' ? 'View All' : 'Ver Todos'}
              </Button>
            </Link>
          </>
        )}
      </div>
    </section>
  );
};

SpeakersList.propTypes = {
  lang: PropTypes.string,
};

export default SpeakersList;
