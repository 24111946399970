import React, { useState } from 'react';
import useStylesProPanel from './styles';

const VerificationStep = ({ email, actualVerificationCode, onVerificationSuccess, onResendCode }) => {
  const classes = useStylesProPanel();
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationError, setVerificationError] = useState('');

  const handleCodeChange = (index, value) => {
    if (value && !/^\d+$/.test(value)) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value && index < 5) {
      const nextInput = document.querySelector(`input[name=code-${index + 1}]`);
      if (nextInput) nextInput.focus();
    }
  };

  const handleVerifyCode = () => {
    const enteredCode = verificationCode.join('');
    if (enteredCode.length !== 6) {
      setVerificationError('Please enter all 6 digits');
      return;
    }

    setIsVerifying(true);
    setVerificationError('');

    // Convert both codes to strings and trim any whitespace
    const normalizedEnteredCode = String(enteredCode).trim();
    const normalizedActualCode = String(actualVerificationCode).trim();

    if (normalizedEnteredCode === normalizedActualCode) {
      onVerificationSuccess();
    } else {
      setVerificationError('Invalid verification code. Please try again.');
    }
    setIsVerifying(false);
  };

  return (
    <>
      <h2 className={classes.loginTitle}>Enter Verification Code</h2>
      <p className={classes.loginDescription}>
        We've sent a 6-digit verification code to your email. Please check your inbox and enter the code below.
      </p>
      <div className={classes.verificationContainer}>
        {verificationCode.map((digit, index) => (
          <input
            key={index}
            type="text"
            name={`code-${index}`}
            maxLength={1}
            className={classes.codeInput}
            value={digit}
            onChange={(e) => handleCodeChange(index, e.target.value)}
          />
        ))}
      </div>
      {verificationError && (
        <p className={classes.errorText} style={{ color: 'red' }}>
          {verificationError}
        </p>
      )}
      <p className={classes.resendText}>
        Didn't receive the code? Check your spam folder or{' '}
        <span
          onClick={onResendCode}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          resend the code
        </span>
      </p>
      <button
        className={classes.sendButton}
        onClick={handleVerifyCode}
        disabled={isVerifying}
      >
        {isVerifying ? 'Verifying...' : 'Verify'}
      </button>
    </>
  );
};

export default VerificationStep;
