import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { formatNameForUrl } from '../../utils/helpers';
import { airtableApi } from '../../services/api';
import { Link } from 'react-router-dom';


import Header from 'components/common/header';
import Footer from 'components/common/footer';

import Facebook from 'assets/icons/FacebookArtist.svg';
import Youtube from 'assets/icons/YoutubeArtist.svg';
import Instagram from 'assets/icons/InstagramArtist.svg';
import Website from 'assets/icons/WebsiteArtist.svg';

import calendar from 'assets/icons/calendar.svg';
import poi from 'assets/icons/poi.svg';
import clock from 'assets/icons/clock.svg';

const ArtistDetailPage = () => {
  const { pathname } = useLocation();
  const { name } = useParams();
  const formattedName = name.replace(/-/g, ' ').toUpperCase();
  const lang = pathname.startsWith('/pt') ? 'pt' : 'en';
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { data, error, isLoading } = useQuery({
    queryKey: ['artistas'],
    queryFn: airtableApi.getArtists,
  });

  const artist = data?.find((artista) => artista.fields.Name.toUpperCase() === formattedName);

  const { data: scheduleData } = useQuery({
    queryKey: ['schedule'],
    queryFn: airtableApi.getSchedule,
  });

  const artistSchedule = scheduleData?.find(
    (schedule) => schedule.fields['Artista']?.[0] === artist?.id
  );

  const getEmbedUrl = (url) => {
    const youtubeMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu.be\/)([a-zA-Z0-9_-]+)/);
    if (youtubeMatch && youtubeMatch[1]) {
      return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    }
    return url;
  };

  return (
    <>
      <Header artists lang={lang} />
      <main className={classes.main}>
        <div className={classes.container}>


          {isLoading && <p>Loading artist details...</p>}
          {error && <p>Error loading artist details: {error.message}</p>}

          {!isLoading && !error && (
            <>
              {!artist ? (
                <p>No artist found with the name: {formattedName}</p>
              ) : (
                <>
                  <div className={classes.row}>
                    <div className={classes.colImage}>
                      <div className={classes.ratio}>
                        {artist.fields.Images && artist.fields.Images.length > 0 && (
                          <img
                            className={classes.image}
                            src={
                              artist.fields.Images[1]?.thumbnails.full.url || artist.fields.Images[0].thumbnails.full.url
                            }
                            alt={artist.fields.Name}
                          />
                        )}
                      </div>
                      {!artist.fields['Partner Image'] || artist.fields['Partner Image'].length === 0 ? (
                        lang === 'en' ? (
                          artist.fields['Selected by'] && (
                            <p className={classes.selectedby}>
                              Selected by: {artist.fields['Selected by']}
                            </p>
                          )
                        ) : (
                          artist.fields['Selected by'] && (
                            <p className={classes.selectedby}>
                              Selecionado por: {artist.fields['Selected by']}
                            </p>
                          )
                        )
                      ) : (
                        <>
                          {lang === 'en' ? (
                            <p className={classes.selectedby}>Selected by:</p>
                          ) : (
                            <p className={classes.selectedby}>Selecionado por:</p>
                          )}
                          {artist.fields["Name (from Parceiro de Programação)"] ? (
                            <Link to={`/partner/${formatNameForUrl(String(artist.fields["Name (from Parceiro de Programação)"]))}`}>
                              <img
                                className={classes.selectedbyImage}
                                src={
                                  artist.fields['Partner Image'][1]?.thumbnails.large.url ||
                                  artist.fields['Partner Image'][0].thumbnails.large.url
                                }
                                alt={artist.fields.Name}
                              />
                            </Link>
                          ) : (
                            <img
                              className={classes.selectedbyImage}
                              src={
                                artist.fields['Partner Image'][1]?.thumbnails.large.url ||
                                artist.fields['Partner Image'][0].thumbnails.large.url
                              }
                              alt={artist.fields.Name}
                            />
                          )}
                        </>
                      )}
                    </div>

                    <div className={classes.column}>

                      <div className={classes.topInfo}>
                        <h1 className={classes.title}>
                          {artist.fields.Name}
                        </h1>

                        <div className={classes.scheduleContainer}>
                          <div className={classes.dateSection}>
                            <img src={calendar} alt="calendar" className={classes.icon} />
                            <span className={classes.dateText}>
                              <span className={classes.highlight}>
                                {artistSchedule?.fields['Name (from Day)'] || 'TBA'}
                              </span>
                            </span>
                          </div>

                          <div className={classes.timeSection}>
                            <div className={classes.timeWrapper}>
                              <img src={clock} alt="clock" className={classes.timeIcon} />
                              <div className={classes.timeText}>
                                {artistSchedule?.fields['Start Time'] ? (
                                  <>
                                    <span className={classes.highlight}>
                                      {artistSchedule.fields['Start Time']}
                                    </span>
                                    <span className={classes.separator}> - </span>
                                    <span className={classes.highlight}>
                                      {artistSchedule.fields['End Time']}
                                    </span>
                                  </>
                                ) : (
                                  <span className={classes.highlight}>TBA</span>
                                )}
                              </div>
                            </div>

                            <div className={classes.venueWrapper}>
                              <img src={poi} alt="poi" className={classes.venueIcon} />
                              <span className={classes.venueName}>
                                {artistSchedule?.fields['Name (from City)'] || 'TBA'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.description}>
                        {lang === 'en' ? (
                          <p> {artist.fields.Bio && artist.fields.Bio}</p>
                        ) : (
                          <p> {artist.fields["BIO-PT"] && artist.fields["BIO-PT"]}</p>
                        )}
                      </div>

                      <div className={classes.embedPlayer} dangerouslySetInnerHTML={{ __html: artist.fields["Embed player"] }} />

                      <div className={classes.RowSocial}>
                        {artist.fields.Facebook && (
                          <a href={artist.fields.Facebook} target="_blank" rel="noopener noreferrer">
                            <img className={classes.socials} src={Facebook} alt="facebook" />
                          </a>
                        )}
                        {artist.fields.Youtube && (
                          <a href={artist.fields.Youtube} target="_blank" rel="noopener noreferrer">
                            <img className={classes.socials} src={Youtube} alt="Youtube" />
                          </a>
                        )}
                        {artist.fields.Instagram && (
                          <a href={artist.fields.Instagram} target="_blank" rel="noopener noreferrer">
                            <img className={classes.socials} src={Instagram} alt="Instagram" />
                          </a>
                        )}
                        {artist.fields.Website && (
                          <a href={artist.fields.Website} target="_blank" rel="noopener noreferrer">
                            <img className={classes.socials} src={Website} alt="Website" />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>

                  {isDesktop && (
                    artist.fields.Video && (
                      <iframe
                        title={artist.fields.name}
                        className={classes.embed}
                        width="100%"
                        height="693px"
                        src={getEmbedUrl(artist.fields.Video)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    )
                  )}

                  {isMobile && (
                    <>
                      {artist.fields.Video ? (
                        <iframe
                          title={artist.fields.name}
                          className={classes.embed}
                          width="100%"
                          height="260px"
                          src={getEmbedUrl(artist.fields.Video)}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      ) : (
                        artist.fields.Embed && (
                          <div className={classes.embed} dangerouslySetInnerHTML={{ __html: artist.fields.Embed }} />
                        )
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </main>
      <Footer lang={lang} />
    </>
  );
};

export default ArtistDetailPage;
