import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import poi from 'assets/icons/poi.svg';
import calendar from 'assets/icons/calendar.svg';

import { formatNameForUrl } from '../../utils/helpers';

const LineupCard = ({ event, lang, isLoading }) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <div className={`${classes.event} ${classes.loading}`}>
        <div className={classes.row}>
          <div className={`${classes.tag} ${classes.skeleton}`}>
            <div className={`${classes.tagIcon} ${classes.skeleton}`} />
            <div className={`${classes.tagText} ${classes.skeleton}`} />
          </div>
          <div className={`${classes.tag} ${classes.skeleton} type`}>
            <div className={`${classes.type} ${classes.skeleton}`} />
          </div>
        </div>
        <div className={`${classes.eventTitle} ${classes.skeleton}`} />
        <div className={`${classes.tag} ${classes.skeleton}`}>
          <div className={`${classes.tagIcon} ${classes.skeleton}`} />
          <div className={`${classes.tagText} ${classes.skeleton}`} />
        </div>
        <div className={`${classes.city} ${classes.skeleton}`} />
      </div>
    );
  }

  return (
    <Link
      to={
        lang === 'en'
          ? `/artist/${formatNameForUrl(event.fields.Name)}`
          : `/pt/artista/${formatNameForUrl(event.fields.Name)}`
      }
      className={classes.event}
    >

      <div className={classes.row}>
        <div className={classes.tag}>
          <img src={calendar} alt="clock" className={classes.tagIcon} />
          <p className={classes.tagText}>
            {event.fields['Name (from Day)']}
          </p>
        </div>
        {/* <div className={classes.tag}>
          <img src={poi} alt="location" className={classes.tagIcon} />
          <div className={classes.tagText}>
             {event.fields['Name (from Venue)']}
          </div>
        </div> */}
        <div className={`${classes.tag} type`}>
          <p className={classes.type}>
            {lang === 'en' ? (event.fields['Name (from Tags)'] || 'Concert') : (event.fields['Name PT (from Tags)'] || 'Concerto')}
          </p>
        </div>
      </div>
      <div className={classes.eventTitle}>
        {event.fields.Name}
      </div>
      <div className={classes.tag}>
        <img src={poi} alt="location" className={classes.tagIcon} />
        <div className={classes.tagText}>
           {/* {event.fields['Name (from Venue)']} */}
           TBA
        </div>
      </div>
      <div className={classes.city}>{event.fields['Name (from City)']}</div>

    </Link>
  );
};

export default LineupCard;
