import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  artistsSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    padding: '5rem 2rem',
    minHeight: '50vh',
  },
  speakersSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '5rem 2rem',
    minHeight: '50vh',
  },
  maxWidth: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '1280px',
  },
  artistsList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '2rem',
    '& div:nth-of-type(2)': {
      marginTop: '70px',
    },
  },
  artists: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33.33%',
    padding: '3rem',
    color: 'var(--red)',
    '& img': {
      height: '250px',
      objectFit: 'contain',
    },
    '& img.big': {
      height: '4.2cm',
      padding: 0,
    },
  },
  artistsTitle: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: 'calc(1rem + 1.6vw)',
    textAlign: 'center',
    color: 'var(--red)',
    paddingBottom: '2rem',
  },
  speakersTitleXL: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: 'calc(1rem + 1.6vw)',
    textAlign: 'center',
    color: 'var(--green)',
    paddingBottom: '2rem',
  },
  speakersTitle: {
    fontFamily: 'Switzer',
    fontSize: 32,
    fontWeight: 600,
    wordWrap: 'break-word',
    padding: '1rem 0',
    '& span': {
      fontSize: '16px',
    },
  },
  artistAbout: {
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: 0.4,
    textAlign: 'center',
    marginTop: '1rem',
    '& span': {
      fontSize: '16px',
    },
  },
  speakerTitle: {
    fontFamily: 'Switzer',
    fontSize: 'calc(24px + 1vw)',
    fontWeight: '600',
    textAlign: 'center',
    color: 'var(--green)',
  },
  imageContainer: {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  speakersList: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '24px',
    flexWrap: 'wrap',
    marginBottom: '3rem',
    '@media (max-width: 1024px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@media (max-width: 768px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
    }
  },
  speakers: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '18px 18px 40px 18px',
    width: '33.33%',
    color: 'var(--green)',
    '& img': {
      width: '100%',
      objectFit: 'cover',
    },
    '& img.big': {
      height: '4.2cm',
      padding: 0,
    },
  },
  '@media (max-width: 1024px)': {
    artistsList: {
      gap: '2rem',
    },
    artists: {
      width: '100%'
    },
    artistsSection: {
      padding: '4rem 1rem',
    },
    speakersList: {
      gap: '2rem',
    },
    speakers: {
      width: '100%',
    },
    speakersSection: {
      padding: '4rem 1rem',
    },
  },
});

export default useStyles;
