import React, { useEffect } from 'react';
import useStyles from '../styles';
import useStylesPartners from './styles';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { airtableApi } from '../../services/api';
import { formatNameForUrl } from 'utils/helpers';

import Header from 'components/common/header';
import Footer from 'components/common/footer';


const Partners = () => {
  const classes = useStyles();
  const pageClasses = useStylesPartners();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  const { data, error, isLoading } = useQuery({
    queryKey: ['ProgrammingPartners'],
    queryFn: airtableApi.getProgrammingPartners,
  });

  const sortedData = data ? [...data].sort((a, b) => a.fields.Name.localeCompare(b.fields.Name)) : [];


  return (
    <div className={classes.App}>
      <Header lang={lang} partners />
      <main className={`${classes.main} ${'theme-light'}`}>
        <div className={classes.container}>
          <div className={pageClasses.introSection}>
            {/* <MarginSection lang={'en'} /> */}
            <h1 className={pageClasses.title}>{lang === 'en' ? 'PARTNERS' : 'PARCEIROS'}</h1>
            {lang === 'en' ? (
              <p className={pageClasses.description}>
                Developed within a network-building framework, SQUARE program will be developed in collaboration with various cultural entities, including labels, promoters, and festivals operating within the spectrum of emerging music across the three continents. Partners will be invited to curate the lineup of artists and the convention program.
              </p>
            ) : (
              <p className={pageClasses.description}>
                Tendo como matriz a construção em rede, o programa do SQUARE será desenvolvido numa colaboração com diversos agentes, editoras, promotoras e festivais a trabalharem no espectro da música emergente nos três continentes. Os parceiros serão convidados a fazer curadoria na programação de artistas e da convenção.
              </p>
            )}
          </div>

          <div className={pageClasses.partnersList}>
            {isLoading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>Error loading data: {error.message}</p>
            ) : (
              sortedData.map((partner) => (
                <Link
                  to={
                    lang === 'pt'
                      ? `/pt/parceiro/${formatNameForUrl(partner.fields.Name)}`
                      : `/partner/${formatNameForUrl(partner.fields.Name)}`
                  }
                  className={pageClasses.partners}
                  key={partner.id}
                >
                  <div className={pageClasses.partnerImage}>
                    {partner.fields.Image && partner.fields.Image.length > 0 && (
                      <img
                        src={partner.fields.Image[0].thumbnails.large.url}
                        className={pageClasses.image}
                        alt={partner.fields.Name}
                        loading="lazy"
                      />
                    )}
                  </div>
                </Link>
              ))
            )}
          </div>

        </div>
      </main>
      <Footer lang={lang} />
    </div>
  );
};

export default Partners;
