import React from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';


import HomePage from 'pages/home';
import Terms from 'pages/terms-and-conditions';
import TermsPT from 'pages/terms-and-conditions-pt';
import Artists from 'pages/festival';
import ArtistDetail from 'pages/artist-detail';
import Conference from 'pages/conference';
import SpeakerDetail from 'pages/speaker-detail';
import Partners from 'pages/partners';
import PartnerDetail from 'pages/partner-detail';
import Tickets from 'pages/tickets';
import FAQs from 'pages/faqs';
import ProPanel from 'pages/pro-panel';
import NewsList from 'pages/news';
import NewsDetail from 'pages/news-detail';
import EventDetail from 'pages/event-detail';

import Login from 'pages/login';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/pt',
    element: <HomePage />,
  },
  {
    path: '/artists',
    element: <Navigate to="/festival" replace />,
  },
  {
    path: '/pt/artistas',
    element: <Navigate to="/pt/festival" replace />,
  },
  {
    path: '/festival',
    element: <Artists />,
  },
  {
    path: '/pt/festival',
    element: <Artists />,
  },
  {
    path: '/artist/:name',
    element: <ArtistDetail />,
  },
  {
    path: '/pt/artista/:name',
    element: <ArtistDetail />,
  },
  {
    path: '/conference',
    element: <Conference />,
  },
  {
    path: '/pt/conferencia',
    element: <Conference />,
  },
  {
    path: '/partners',
    element: <Partners />,
  },
  {
    path: '/pt/parceiros',
    element: <Partners />,
  },
  {
    path: '/partner/:name',
    element: <PartnerDetail />,
  },
  {
    path: '/pt/parceiro/:name',
    element: <PartnerDetail />,
  },
  {
    path: '/speaker/:name',
    element: <SpeakerDetail />,
  },
  {
    path: '/pt/orador/:name',
    element: <SpeakerDetail />,
  },
  {
    path: '/terms-and-conditions',
    element: <Terms />,
  },
  {
    path: '/pt/terms-and-conditions',
    element: <TermsPT />,
  },
  {
    path: '/tickets',
    element: <Tickets />,
  },
  {
    path: '/pt/bilhetes',
    element: <Tickets />,
  },
  {
    path: '/faqs',
    element: <FAQs />,
  },
  {
    path: '/pt/faqs',
    element: <FAQs />,
  },
  {
    path: '/news',
    element: <NewsList />,
  },
  {
    path: '/pt/noticias',
    element: <NewsList />,
  },
  {
    path: '/news/:name',
    element: <NewsDetail />,
  },
  {
    path: '/pt/noticias/:name',
    element: <NewsDetail />,
  },
  {
    path: '/event/:name',
    element: <EventDetail />,
  },
  {
    path: '/pt/evento/:name',
    element: <EventDetail />,
  },
  {
    path: '/pro-panel',
    element: <ProPanel />,
  },
  {
    path: '/pt/pro-panel',
    element: <ProPanel />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/pt/login',
    element: <Login />,
  }
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
