import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useStylesTickets from './styles';
import useStyles from '../styles';

import Header from 'components/common/header';
import Footer from 'components/common/footer';

import TheSpacer from 'components/common/spacer/thespacer';
import Button from 'components/button/index.js';

const Tickets = () => {
  const classesGlobal = useStyles();
  const classes = useStylesTickets();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  const TicketBox = ({ name, price, description, link, isPro, lang }) => {
    return (
      <div className={classes.ticketBox}>
        <div className={classes.row}>
          <div className={classes.column}>
            <h1 className={classes.title}>{name}</h1>
            <p className={classes.price}>
              €{price} {lang === 'en' ? '(+ box office fees)' : '(+ taxas de bilheteira)'}
            </p>
          </div>

          <a href={link} target="_blank" className={'no-underline'} rel="noreferrer">
            <Button background="green" color="brown" fontSize="18px" padding={'12px 18px'}>
              {lang === 'en' ? 'BUY TICKET' : 'COMPRAR BILHETE'}
            </Button>
          </a>
        </div>

        {lang === 'en' ? (
          <p className={classes.description}>
            Holders of the {name} are entitled to {description}
          </p>
        ) : (
          <p className={classes.description}>
            Os portadores do {name} têm direito a {description}
          </p>
        )}

        {isPro && <TheSpacer size={8} vertical />}
        {isPro && (
          <>
            {lang === 'en' ? (
              <ul className={classes.bullets}>
                <li>Access the full program of the Square Conferences;</li>
                <li>
                  Access the full day and night music program, taking place over the four days of the event in the
                  cities of Braga, Barcelos, Famalicão, and Guimarães;
                </li>
                <li>
                  Round-trip transfer between the city of Braga and the three partner cities: Barcelos, Famalicão, and
                  Guimarães;
                </li>
                <li>Networking lunch and dinner on January 29th, 30th, and 31st, 2025;</li>
                <li>Access to the database of professionals attending the event.</li>
              </ul>
            ) : (
              <ul className={classes.bullets}>
                <li>Aceder a toda a programação das Conferências Square;</li>
                <li>
                  Aceder a toda a programação musical, diurna e noturna, a decorrer ao longo dos de quatro dias do
                  evento nas cidades de Braga, Barcelos, Famalicão e Guimarães;
                </li>
                <li>
                  Transfer de ida e volta entre a cidade de Braga e as três cidades parceiras: Barcelos, Famalicão e
                  Guimarães;
                </li>
                <li>Almoço e jantar de networking nos dias 29, 30 e 31 de janeiro de 2025;</li>
              </ul>
            )}
          </>
        )}
        <TheSpacer size={24} vertical />
        <p className={classes.description} style={{ fontStyle: 'italic' }}>
          {lang === 'en'
            ? 'Due to the multi-venue format of the event, access to the spaces is subject to capacity limitations.'
            : 'Devido ao formato multi-venue do evento, o acesso aos espaços está limitado à lotação dos mesmos.'}
        </p>
      </div>
    );
  };

  return (
    <div className={classesGlobal.App}>
      <Header lang={lang} tickets />

      <main className={classesGlobal.main}>
        <div className={classes.container}>
          <TicketBox
            lang={lang}
            name={lang === 'en' ? 'PRO PASS' : 'PASSE PRO'}
            price={'50'}
            description={':'}
            isPro
            link={'https://www.bol.pt/Comprar/Bilhetes/145483-square_passe_pro_pro_pass-gnration/'}
          />
          <TheSpacer size={32} vertical />
          <TicketBox
            lang={lang}
            name={lang === 'en' ? 'GENERAL PASS' : 'PASSE GERAL'}
            price={'25'}
            description={
              lang === 'en'
                ? 'access the full day and night music program, taking place over the four days of the event in the cities of Braga, Barcelos, Famalicão, and Guimarães.'
                : 'aceder a toda a programação musical, diurna e noturna, a decorrer ao longo dos de quatro dias do evento nas cidades de Braga, Barcelos, Famalicão e Guimarães.'
            }
            link={'https://www.bol.pt/Comprar/Bilhetes/145484-square_passe_geral_general_pass-gnration/'}
          />
          <TheSpacer size={32} vertical />
          <TicketBox
            lang={lang}
            name={lang === 'en' ? 'SATURDAY PASS' : 'PASSE SÁBADO'}
            price={'15'}
            description={
              lang === 'en'
                ? 'access all the program scheduled for February 1st in the city of Braga.'
                : 'aceder à programação agendada para sábado, dia 1 de fevereiro, na cidade de Braga. Devido ao formato multi-venue do evento, o acesso aos espaços está limitado à lotação dos mesmos.'
            }
            link={'https://www.bol.pt/Comprar/Bilhetes/145485-square_passe_sabado_saturday_pass-theatro_circo/'}
          />
        </div>
      </main>

      <Footer lang={lang} />
    </div>
  );
};

export default Tickets;
