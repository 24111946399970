import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './styles.js';
import { formatNameForUrl } from '../../utils/helpers.js';

const ArtistCard = ({ artist, lang }) => {
  const classes = useStyles();

  return (
    <Link
      to={
        lang === 'pt'
          ? `/pt/artista/${formatNameForUrl(artist.fields.Name)}`
          : `/artist/${formatNameForUrl(artist.fields.Name)}`
      }
      className={classes.artists}
      key={artist.id}
    >
      <div className="col justify-center align-center">
        {artist.fields.Images && artist.fields.Images.length > 0 && (
          <img
            src={artist.fields.Images[0].thumbnails.large.url}
            className={classes.image}
            alt={artist.fields.Name}
            loading="lazy"
          />
        )}
        <p className={classes.artistTitle}>
          {artist.fields.Name} <span>{artist.fields.Country}</span>
        </p>
        {lang === 'en' ? (
          <p className={classes.artistAbout}>
            Selected by <span>{artist.fields['Selected by']}</span>
          </p>
        ) : (
          <p className={classes.artistAbout}>
            Selecionado por <span>{artist.fields['Selected by']}</span>
          </p>
        )}
      </div>
    </Link>
  );
};

export default ArtistCard;
