import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  list: {
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '24px',
    padding: '0 20px',
    marginBottom: '6rem',
    color: 'white',
    '@media (max-width: 1024px)': {
      padding: '0px',
    },
    '@media (max-width: 780px)': {
      gridTemplateColumns: '1fr',
    },
  }
});

export default useStyles;
