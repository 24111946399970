import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  main: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'var(--main-background-color)',
    gap: '3rem',
    overflow: 'hidden',
    '&.pro-panel': {
      backgroundColor: 'var(--grey)',
      minHeight: '100vh'
    },
    '&.theme-light': {
      backgroundColor: 'white',
    },
    '&.isSingle': {
      padding: 0,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '83.33%',
    maxWidth: '1280px',
    margin: '0 auto',
    padding: 'calc(300px - 6rem) 2rem 120px',
  },
  artistsList: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '2rem',
  },
  card: {
    width: 'calc(100% - 24vw)',
    margin: '20vh 0',
    position: 'relative',
    padding: '3.33vw',
    maxWidth: '960px',
    '&.isSingle': {
      margin: 0,
    },
  },
  ratio: {
    width: '100%',
    paddingBottom: '36%',
    position: 'relative',
    zIndex: 10,
  },
  cardText: {
    fontFamily: 'Switzer',
    fontSize: '20px',
    color: '#3f310c',
    lineHeight: 1.4,
    margin: '0px',
  },
  seeMoreWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    height: 'auto',
  },
  seeMoreHidden: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    width: '100%',
    maxHeight: '0',
    paddingBottom: '1rem',
    marginTop: '2em',
    overflow: 'hidden',
    transition: 'all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  },
  seeMoreVisible: {
    maxHeight: '900px',
  },
  seeMoreButton: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    height: '40px',
    color: '#3f310c',
    fontFamily: 'Switzer',
    fontSize: '20px',
    letterSpacing: '0.4px',
    textDecoration: 'underline',
    '&:hover': {
      color: '#3f310c',
    },
  },
  bgGreen: {
    backgroundColor: 'var(--main-color)',
  },
  bgLilas: {
    backgroundColor: '#bf80ec',
  },
  maxWidth: {
    width: '100%',
    maxWidth: '860px',
  },
  image1: {
    position: 'absolute',
    bottom: '10%',
    right: '-10vw',
    '& img': {
      width: '30vw',
      minWidth: '200px',
      maxWidth: '400px',
      objectFit: 'contain',
      border: '3px solid var(--secondary-color)',
    },
  },
  image2: {
    position: 'absolute',
    bottom: '-12.5%',
    left: '-10vw',
    '& img': {
      width: '30vw',
      minWidth: '200px',
      maxWidth: '400px',
      objectFit: 'contain',
      border: '3px solid var(--secondary-color)',
    },
  },
  image3: {
    position: 'absolute',
    minWidth: '200px',
    maxWidth: '400px',
    right: '-10vw',
    transform: 'translateY(-50%)',
    '& img': {
      width: '25vw',
      minWidth: '200px',
      maxWidth: '400px',
      minHeight: '260px',
      objectFit: 'contain',
      border: '3px solid var(--main-color)',
    },
  },
  image4: {
    position: 'absolute',
    bottom: '10%',
    right: '-10vw',
    '& img': {
      width: '30vw',
      minWidth: '200px',
      maxWidth: '400px',
      minHeight: '260px',
      objectFit: 'cover',
      border: '3px solid var(--secondary-color)',
    },
  },
  image5: {
    position: 'absolute',
    left: '-10vw',
    transform: 'translateY(-50%)',
    '& img': {
      width: '30vw',
      minWidth: '200px',
      maxWidth: '400px',
      minHeight: '260px',
      objectFit: 'cover',
      border: '3px solid var(--secondary-color)',
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  buttonOpenCall: {
    backgroundColor: 'var(--main-color)',
    padding: '1rem 1.5rem',
    fontFamily: 'Switzer',
    fontSize: '22px',
    fontWeight: 600,
    color: '#3f310c',
    letterSpacing: '0.4px',
    transition: '250ms all',
    borderRadius: '2rem',
    position: 'relative',
    zIndex: 1,
    '&:hover': {
      backgroundColor: 'var(--main-background-color)',
      color: 'var(--main-color)',
    },
  },
  sectionTitle: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '25px',
    paddingBottom: '2rem',
  },
  '@media (max-width: 1024px)': {
    container: {
      width: '100%',
      padding: '120px 1.5rem 80px 1.5rem',
    },

    videoMobile: {
      display: 'block',
    },
    videoDesktop: {
      display: 'none',
    },
    card: {
      width: 'calc(100% - 3rem)',
      padding: '1.5rem',
      margin: '4rem 0',
    },
    image1: {
      right: '-5%',
      display: 'none',
    },
    image2: {
      right: '-5%',
      display: 'none',
    },
    image3: {
      right: '-5%',
      transform: 'translateY(-8%)',
      display: 'none',
    },
    image4: {
      right: '-5%',
      display: 'none',
    },
    image5: {
      right: '-5%',
      display: 'none',
    },
    cardText: {
      fontSize: '1rem',
      marginTop: '0',
    },
    seeMoreButton: {
      fontSize: '1rem',
    },
    rectangle: {
      width: '200px',
      height: '150px',
      left: '4rem',
      right: 'auto',
      bottom: '-75px',
    },
    buttonOpenCall: {
      fontSize: '1rem',
      padding: '1rem',
    },
    partnerList: {
      gap: '2rem',
    },
    partner: {
      width: 'calc(50% - 2rem)',
      justifyContent: 'flex-start',
      '& img': {
        height: '50px',
      },
    },
    partnerSection: {
      padding: '4rem 1rem',
    },
  },
});

export default useStyles;
